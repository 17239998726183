import {FunctionComponent, PropsWithChildren, useEffect, useState} from 'react';
import './copy-icon.css';

type ShareIconProps = {
    onClick: () => void
};

const CopyIcon: FunctionComponent<ShareIconProps> = ({onClick}) => {
    return (
        <div className="CopyIcon" onClick={() => onClick()}/>
    );
}

export default CopyIcon;
