import React, {FunctionComponent} from 'react';
import {Demo} from '../../models/demo';
import SongRow from './row';

type SongListProps = {
    demos: Array<Demo>
    onSelectAtIndex: (ix: number) => void
    onToggleLikeAtIndex?: (ix: number) => void
    onShareAtIndex: (ix: number) => void
    onSelectVoiceAtIndex: (ix: number) => void
};

const DemoList: FunctionComponent<SongListProps> = ({
                                                        demos,
                                                        onSelectAtIndex,
                                                        onShareAtIndex,
                                                        onSelectVoiceAtIndex,
                                                        onToggleLikeAtIndex
                                                    }) => {
    if (demos.length === 0) return <div>No demos</div>;

    return (
        <div>
            {demos.map((demo, ix) => (
                <SongRow key={demo.id}
                         demo={demo}
                         onSelect={() => onSelectAtIndex(ix)}
                         onSelectVoice={() => onSelectVoiceAtIndex(ix)}
                         onShare={() => onShareAtIndex(ix)}
                         onToggleLike={onToggleLikeAtIndex ? () => onToggleLikeAtIndex(ix) : undefined}
                />
            ))}
        </div>
    );
};

export default DemoList;
