import React, {FunctionComponent, useState} from 'react';
import {UserLoginProps} from '../../types/user-login-props';
import Modal from '../modals/modal';
import ModalContent from '../modals/modal-content';
import FormRow from '../form/form-row';
import TextInput from '../form/text-input';
import Button, {ButtonStyle} from '../buttons/button';
import api from '../../services/api';
import LoadingIndicator from '../common/loading-indicator';
import ErrorList from '../common/error-list';
import LoginMethod from '../../enums/login-method';

type RequestPasswordResetModalProps = UserLoginProps

enum ResetStage {
    REQUEST,
    RESET,
    DONE
}

const ResetPasswordModal: FunctionComponent<RequestPasswordResetModalProps> = ({switchLoginMethod}) => {
    const [resetStage, setResetStage] = useState<ResetStage>(ResetStage.REQUEST);
    const [busy, setBusy] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errors, setErrors] = useState<Array<string>>([]);

    function handleResetRequest() {
        setBusy(true);
        setErrors([]);
        api().auth.requestPasswordReset(email).then(() => {
            setBusy(false);
            setResetStage(ResetStage.RESET);
        });
    }

    function handleReset() {
        setBusy(true);
        setErrors([]);
        api().auth.resetPassword(email, code, password).then(result => {
            setBusy(false);
            if (result.success) setResetStage(ResetStage.DONE);
            else setErrors(result.errors);
        });
    }

    const validEmail = email.length > 0 && email.match(/.+@.+\..+/);
    let view;

    if (!switchLoginMethod) return <div>Misconfigured</div>

    switch (resetStage) {
        case ResetStage.REQUEST:
            view = <>
                <h4>Request Password Reset</h4>
                <ErrorList errors={errors}/>
                {busy ? <><LoadingIndicator/> processing</> : (
                    <form>
                        <FormRow>
                            Email:&nbsp;
                            <TextInput value={email}
                                       autoComplete="username"
                                       onChange={value => setEmail(value)}
                            />
                        </FormRow>
                        <Button onClick={handleResetRequest} disabled={!validEmail}>Send Reset Link</Button>
                    </form>
                )}
            </>;
            break;

        case ResetStage.RESET:
            const numDigits = 6;

            view = <>
                <h4>Reset Password</h4>
                <ErrorList errors={errors}/>
                <p>If an account is associated with the email you entered, a code was sent to your email. Enter that
                    code below.</p>
                {busy ? <><LoadingIndicator/> resetting password</> : (
                    <form>
                        <FormRow>
                            Code:&nbsp;
                            <TextInput value={code}
                                       onChange={val => {
                                           if (val.match(/^[0-9]*$/) && val.length <= numDigits) setCode(val);
                                       }}/>
                        </FormRow>
                        <FormRow>
                            New Password:&nbsp;
                            <TextInput type="password" value={password} onChange={val => setPassword(val)}/>
                        </FormRow>
                        <FormRow>
                            <Button disabled={code.length !== 6 || password.length < 8} onClick={handleReset}>Update
                                Password</Button>
                        </FormRow>
                    </form>
                )}
            </>;
            break;

        case ResetStage.DONE:
            view = <>
                <h4>Well Done!</h4>
                <p>Your password has been reset.</p>
                <Button style={ButtonStyle.Primary}
                        onClick={() => {
                            switchLoginMethod(LoginMethod.Login)
                        }}>Login</Button>
            </>
            break;

        default:
            view = <div>Misconfigured</div>
    }

    return (
        <Modal className="Modal-auth Modal-auth-login">
            <ModalContent>
                {view}
            </ModalContent>
        </Modal>
    );
}

export default ResetPasswordModal;
