import React, {FunctionComponent} from 'react';
import ProfileVoicesButton from '../components/buttons/profile-voices-button';
import QuoteStart from '../assets/img/quote-start.svg';
import QuoteEnd from '../assets/img/quote-end.svg';
import './about.css';

const AboutPage: FunctionComponent = () => {
    return (
        <div className="About container">
            <div className="row">
                <div className="col-6 col-md-12">
                    <div className="hero">
                        <main>
                            <h1>We are here for the PODCAST </h1>
                            <p>We are PODCAST by Cygnus, an English to Spanish translation, voice-over casting and audio production service company.</p>
                            <div className="About-choose-voice">
                                <label>
                                    Choose your<br/>
                                    favorite voice!
                                </label>
                                <ProfileVoicesButton/>
                            </div>
                        </main>
                    </div>
                </div>
                <div className="About-graphic">
                    <div className="About-microphone">
                        <blockquote>
                            <img src={QuoteStart} style={{width: '1em'}}/> We dream of a future<br/>
                            with more listeners. <img src={QuoteEnd} style={{width: '1em'}}/>
                        </blockquote>
                        <img src="/assets/img/about/microphone.png" className="microphone"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutPage;
