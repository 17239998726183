import BaseApi from './base';
import AuthUser from '../../models/auth-user';
import OptionValue from '../../models/option-value';
import {OptionValues} from '../../types/option-values';
import {Profile} from '../../types/profile';
import {Demo} from '../../models/demo';
import {UploadProgress} from './demo';
import {ServerResponseSuccess} from '../../types/server-response-success';

type ProfileResponse = {
    profile: {
        age?: OptionValue
        gender?: OptionValue
        voice?: OptionValue
        phone?: string
    }
    options?: OptionValues
    unfinishedDemos: Array<Demo>
    photo?: string
};

type ProfileRequestData = {
    'age-group'?: number
    gender?: number
    'voice-type'?: number
    phone?: string
    name?: string
    email?: string
}

class ProfileApi extends BaseApi {
    getOwnProfile(authUser: AuthUser, includeOptions: boolean = false): Promise<ProfileResponse> {
        const config = this.createDefaultConfigWithAuthorization(authUser);
        if (includeOptions) {
            if (!config.params) config.params = {};
            config.params.options = 'true';
        }
        return this.api.get<ProfileResponse>('/account/profile', config).then(response => response.data);
    }

    update(authUser: AuthUser, profile: Profile): Promise<void> {
        const config = this.createDefaultConfigWithAuthorization(authUser);

        const data: ProfileRequestData = {};
        if (profile.age) data['age-group'] = profile.age.id;
        if (profile.gender) data['gender'] = profile.gender.id;
        if (profile.voice) data['voice-type'] = profile.voice.id;
        if (profile.phone) data['phone'] = profile.phone;
        if (profile.username) data['email'] = profile.username;
        if (profile.name) data['name'] = profile.name;

        return this.api.put('/account/profile', data, config);
    }

    // uploadProfilePhoto(authUser: AuthUser, file: File, onProgress?: UploadProgress): Promise<Demo> {
    uploadPhoto(authUser: AuthUser, file: File, onProgress?: UploadProgress): Promise<ServerResponseSuccess> {
        const config = this.createDefaultConfigWithAuthorization(authUser);
        config.headers['Content-Type'] = 'multipart/form-data';
        const data = new FormData();
        data.append('photo', file);
        if (onProgress) {
            config.onUploadProgress = (ev) => {
                onProgress(ev.loaded, ev.total);
            };
        }

        return this.api
            .post<ServerResponseSuccess, FormData>('/account/profile/photo', data, config)
            .then(response => response.data);
    }
}

export default ProfileApi;
