import React, {FunctionComponent} from 'react';
import './fastforward-button.css';
import fastForwardDarkImage from '../../assets/img/fast-forward-dark.svg';

type FastForwardButtonProps = {
  onClick: () => void
};

const FastForwardButton: FunctionComponent<FastForwardButtonProps> = ({onClick}) => {
  return (
    <div className="player-FastForwardButton" onClick={onClick}>
      <img src={fastForwardDarkImage} style={{height: 30}} alt="Fast Forward Button"/>
    </div>
  );
};

export default FastForwardButton;
