import React, {
    ChangeEventHandler,
    FunctionComponent,
    ReactElement,
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react';
import './steps.css';
import AppContext from '../../contexts/app-context';
import ErrorList from '../common/error-list';
import Button from '../buttons/button';
import ProgressBar from '../player/progress-bar';
import api from '../../services/api';

type Status = {
    isSaving: boolean
    errors: Array<string>
    message: string,
    percentDone: number
}

type PhotoUploderProps = {
    onUploadDone?: () => void
    onFileReady?: (file: File) => void // When an upload is determined to be valid locally then fire this event with the file so that it can be stored, etc. in other components
    onUploading?: () => void
    buttonLabel?: string
    children?: ReactNode
}

const PhotoUploader: FunctionComponent<PhotoUploderProps> = ({
                                                                 buttonLabel,
                                                                 children,
                                                                 onFileReady,
                                                                 onUploadDone
                                                             }) => {
    const fileRef = useRef<HTMLInputElement>(null);
    const appContext = useContext(AppContext);
    const [uploadQueue, setUploadQueue] = useState<Array<File>>([]);
    const [status, setStatus] = useState<Status>({
        isSaving: false,
        message: '',
        errors: [],
        percentDone: 0
    });

    let isActive = useRef<boolean>(true);

    const handleChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
        if (!ev.target.files || ev.target.files.length === 0) return;
        const file: File = ev.target.files[0];

        if (file.name.substring(file.name.length-4) !== '.jpg') {
            setStatus(prevState => ({
                ...prevState,
                errors: ['Uploaded file must be an MP3']
            }));
            console.warn('Invalid extension: ', file.name, file.name.substring(file.name.length-4));
            return;
        }
        setUploadQueue([file]);
        setStatus(prevState => ({
            errors: [],
            isSaving: true,
            percentDone: 0,
            message: 'Uploading...'
        }));
        if (isActive.current) {
            setStatus(prevState => {
                return {
                    ...prevState,
                    isSaving: true,
                    message: ''
                };
            });
            api().profile.uploadPhoto(
                appContext.authUser!,
                file,
                (sizeUploaded, totalSize) => {
                    const percentDone = Math.round(sizeUploaded / totalSize * 100_00) / 100;
                    setStatus(prevState => {
                        return {
                            ...prevState,
                            percentDone,
                            message: 'Uploading (' + percentDone + '%)...'
                        }
                    });
                })
                .then(() => {
                    // setUploadQueue([]);
                    // Keeping both callbacks for now in case we want to mess with timing in the future
                    if (onFileReady) onFileReady(file);
                    if (onUploadDone) onUploadDone();

                    ev.target.value = '';
                    setStatus(prevState => {
                        return {
                            message: 'Done',
                            errors: [],
                            isSaving: false,
                            percentDone: 100
                        }
                    });
                })
                .catch(e => {
                    ev.target.value = '';
                    setStatus(prevState => {
                        return {
                            ...prevState,
                            isSaving: false,
                            errors: ['An unknown error occurred while attempting to upload the file', e.message],
                            percentDone: 0
                        }
                    });
                });
        }
    };

    // Keep track of whether this component is active so that uploads do not modify
    useEffect(() => {
        isActive.current = true;
        return () => {
            isActive.current = false;
        }
    }, []);

    return <>
        <ErrorList errors={status.errors}/>
        <form encType="multipart/form-data">
            <input type="file"
                   ref={fileRef}
                   name="file"
                   id="audiofile"
                   style={{display: 'none'}}
                   onChange={handleChange}
                   accept=".jpg"
            />
            {status.isSaving ? (
                <main className="center">
                    <div>
                        <ProgressBar percent={status.percentDone}/>
                    </div>
                    {uploadQueue.map(upload => {
                        return <div key={upload.name}>
                            {upload.name}
                        </div>
                    })}
                </main>
            ) : null}

            {children ?
                <div className="clickable" onClick={() => fileRef.current?.click()}>
                    {children}
                </div>
                :
                <Button disabled={status.isSaving}
                        onClick={() => fileRef.current?.click()}>
                    {buttonLabel ? buttonLabel : 'Upload Profile Photo'}
                </Button>
            }
        </form>
    </>;
}

export default PhotoUploader;
