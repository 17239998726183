import {FunctionComponent, useState} from 'react';
import LoginModal from './login-modal';
import LoginMethod from '../../enums/login-method';
import AuthUser from '../../models/auth-user';
import RegisterModal from './register-modal';
import ResetPasswordModal from './reset-password-modal';

type LoginRegisterModalProps = {
    login: (authUser: AuthUser) => void
    loginMethod?: LoginMethod
};

const LoginRegisterModal: FunctionComponent<LoginRegisterModalProps> = ({login, loginMethod}) => {
    const [currentLoginMethod, setCurrentLoginMethod] = useState<LoginMethod>(loginMethod ?? LoginMethod.Register);

    function switchLoginMethod(setLoginMethod?: LoginMethod) {
        if (setLoginMethod === undefined) {
            if (currentLoginMethod === LoginMethod.Register) {
                setLoginMethod = LoginMethod.Login;
            } else {
                setLoginMethod = LoginMethod.Register;
            }
        }
        setCurrentLoginMethod(setLoginMethod);
    }

    switch(currentLoginMethod) {
        case LoginMethod.Login:
            return <LoginModal login={login} switchLoginMethod={switchLoginMethod}/>
        case LoginMethod.ResetPassword:
            return <ResetPasswordModal login={login} switchLoginMethod={switchLoginMethod}/>
        case LoginMethod.Register:
        default:
            return <RegisterModal login={login} switchLoginMethod={switchLoginMethod}/>
    }
}

export default LoginRegisterModal;
