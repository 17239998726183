import React, {FunctionComponent, useState} from 'react';
import Modal from '../modals/modal';
import AuthUser from '../../models/auth-user';
import ModalContent from '../modals/modal-content';
import FormRow from '../form/form-row';
import Label from '../form/label';
import ErrorList from '../common/error-list';
import TextInput from '../form/text-input';
import Button, {ButtonStyle} from '../buttons/button';
import api from '../../services/api';
import './modal.css';
import {UserLoginProps} from '../../types/user-login-props';
import SwitchLoginMethod from './switch-login-method';
import LoginMethod from '../../enums/login-method';
import config from '../../config/config';
import PhoneInput from '../form/phone-input';

type RegistModalProps = UserLoginProps

enum RegisterState {
    Form,
    Processing,
    Success
}

const RegisterModal: FunctionComponent<RegistModalProps> = ({login, switchLoginMethod}) => {
    const [loginState, setLoginState] = useState<RegisterState>(RegisterState.Form);
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [errors, setErrors] = useState<Array<string>>([]);

    function handleLogin() {
        const e = [];
        setErrors([]);

        if (email.length === 0) e.push('Email is required');
        else if (!email.match(/.+@.+\..+/)) e.push('Invalid email format');

        if (password.length === 0) e.push('Password is required');
        else if (confirmPassword !== password) e.push('Confirmation password does not match');

        if (e.length === 0) {
            setLoginState(RegisterState.Processing);
            api().auth.register(email, password, name, phone).then(auth => {
                if (auth.success) {
                    setLoginState(RegisterState.Success);
                    setTimeout(function () {
                        const authUser = new AuthUser(email, auth.name!, auth.token!);
                        login(authUser);
                    }, 1000);
                } else {
                    setLoginState(RegisterState.Form);
                    setErrors(auth.messages);
                }
            })
                .catch(e => {
                    setLoginState(RegisterState.Form);
                    setErrors(['An unknown error occurred when communicating with the server.']);
                });
        } else {
            setErrors(e);
        }
    }

    let content = null;
    switch (loginState) {
        case RegisterState.Success:
            content = <p style={{textAlign: 'center'}}>Success!</p>;
            break;

        case RegisterState.Processing:
            content = <p style={{textAlign: 'center'}}>Processing</p>;
            break;

        case RegisterState.Form:
        default:
            content = (
                <>
                    <h4>Register</h4>
                    <ErrorList errors={errors}/>
                    <form>
                        <FormRow>
                            <Label>Email:</Label>
                            <TextInput value={email}
                                       autoComplete="username"
                                       valid={email.length === 0 || isValidEmail(email)}
                                       onChange={value => setEmail(value)}/>
                        </FormRow>
                        <FormRow>
                            <Label>Phone:</Label>
                            <PhoneInput value={phone} valid={isValidPhone(phone)}
                                        onChange={value => setPhone(value)}/>
                        </FormRow>
                        <FormRow>
                            <Label>Password:</Label>
                            <TextInput type="password"
                                       value={password}
                                       autoComplete="new-password"
                                       onChange={value => setPassword(value)}/>
                        </FormRow>
                        <FormRow>
                            <Label>Confirm Password:</Label>
                            <TextInput type="password" value={confirmPassword}
                                       onChange={value => setConfirmPassword(value)}/>
                        </FormRow>
                        <FormRow>
                            <Label>Name:</Label> <TextInput value={name} onChange={value => setName(value)}/>
                        </FormRow>
                        <div className="Modal-auth-actions">
                            <Button style={ButtonStyle.Primary}
                                    onClick={() => handleLogin()}
                                    disabled={!isValidPhone(phone) || !isValidEmail(email)}
                            >Register</Button>
                        </div>
                    </form>
                    {switchLoginMethod ?
                        <SwitchLoginMethod current={LoginMethod.Register} setMethod={switchLoginMethod}/> : null}
                </>
            );
    }
    return <Modal className="Modal-auth Modal-auth-register">
        <ModalContent>
            {content}
        </ModalContent>
    </Modal>
}

function isValidPhone(phone: string): boolean {
    if (!config.registration.phoneRequired && phone.length === 0) return true;
    const tPhone = phone.replace(/[^0-9]+/g, '');
    return tPhone.length >= 10;
}

function isValidEmail(email: string): boolean {
    if (!config.registration.emailRequired && email.length === 0) return true;

    if (email.match(/.+@.+\..+/)) {
        return true;
    }

    return false;
}

export default RegisterModal;
