import {AppContextType} from '../../contexts/app-context';
import {Demo} from '../../models/demo';
import {requireLoggedInUser} from '../../utils/users';
import DemoSelectConfirmationModal from '../../components/modals/demo-select-confirmation-modal';
import React from 'react';

function handleSelectVoice(appContext: AppContextType, demo: Demo) {
    requireLoggedInUser(appContext).then(authUser => {
        if (!authUser) return;
        if (demo.saved) { // "unsaving"
            appContext.demoService?.deselectVoice(authUser, demo.id);
        } else { // Saving
            appContext.modalManager.pushModal(<DemoSelectConfirmationModal/>)
            appContext.demoService?.selectVoice(authUser, demo.id);
        }
    });
}

export default handleSelectVoice;
