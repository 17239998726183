import {FunctionComponent} from 'react';
import './social-icon.css';

type SocialIconProps = {
    alt: string
    href: string
    src: string
}

const SocialIcon: FunctionComponent<SocialIconProps> = ({alt, href, src}) => {
    return (
        <a href={href} target="_blank" rel="noreferrer"><img src={src} className="SocialIcon" alt={alt}/></a>
    );
}

export default SocialIcon;
