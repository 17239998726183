import {FunctionComponent} from 'react';
import TextInput, {TextInputProps} from './text-input';

type PhoneInputProps = TextInputProps;

const PhoneInput: FunctionComponent<PhoneInputProps> = (props) => {
    let {value, onChange, onKeyDown, ...extraProps} = props;

    function handleChange(value: string) {
        const newValue = value.replace(/[^0-9]+/g, '');
        onChange(newValue);
    }

    value = formatPhone(value);

    return (
        <TextInput value={value}
                   onChange={handleChange}
                   onKeyDown={ev => {
                       if (onKeyDown) onKeyDown(ev);
                   }}
                   {...extraProps}/>
    );
}

function formatPhone(value: string): string {
    return value;
    // let displayPhone = '+1';
    // // value = value.padEnd(10, 'x');
    // displayPhone = '(' + value.substring(0, 3) + ') ' + value.substring(3, 6) + (value.length >= 6 ? '-' : '') + value.substring(6, 10);
    // return displayPhone;
}

export default PhoneInput;
