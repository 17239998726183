import {FunctionComponent} from 'react';
import LoginMethod from '../../enums/login-method';
import Button, {ButtonStyle} from '../buttons/button';

type SwitchLoginMethodProps = {
    setMethod: (method: LoginMethod) => void
    current: LoginMethod
};

const SwitchLoginMethod: FunctionComponent<SwitchLoginMethodProps> = ({current, setMethod}) => {
    const loginOptions: Array<{ method: LoginMethod, label: string }> = [
        {method: LoginMethod.Login, label: 'Already a user?'},
        {method: LoginMethod.Register, label: 'Not a user?'}
    ].filter(loginOption => loginOption.method !== current); // Ensure that the current option is not available

    return <>
        {loginOptions.map((loginOption, ix) => (
            <div key={loginOption.method}>
                <Button style={ButtonStyle.Link}
                        onClick={() => setMethod(loginOption.method)}
                >{loginOption.label}</Button>
            </div>
        ))}
        {current === LoginMethod.Login && <Button style={ButtonStyle.Link}
                                                  onClick={() => setMethod(LoginMethod.ResetPassword)}>Forgot password?</Button>}
    </>
}

export default SwitchLoginMethod;
