import {FunctionComponent, PropsWithChildren} from 'react';
import './list-option.css';
import classNames from 'classnames';

type ListOptionProps = PropsWithChildren<{
    onClick?: () => void
}>;

const ListOption: FunctionComponent<ListOptionProps> = ({children, onClick}) => {
    return <li className={classNames('ListOption', {clickable: onClick !== null})}
               onClick={() => {
                   if (onClick) onClick();
               }}>
        {children}
    </li>
};

export default ListOption