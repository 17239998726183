import React, {FunctionComponent} from 'react';
import './rewind-button.css';
import rewindDarkImage from '../../assets/img/rewind-dark.svg';

type RewindButtonProps = {
  onClick: () => void
};

const RewindButton: FunctionComponent<RewindButtonProps> = ({onClick}) => {
    return (
        <div className="player-RewindButton" onClick={onClick}>
          <img src={rewindDarkImage} style={{height: 30}} alt="Rewind Button"/>
        </div>
    );
};

export default RewindButton;
