import {API} from '../api';
import AuthUser from '../../models/auth-user';
import {AxiosRequestConfig, AxiosRequestHeaders} from 'axios';

type AxiosAuthorizationConfig<D> = AxiosRequestConfig<D> & {
  headers: AxiosRequestHeaders
}

class BaseApi {
  constructor(protected api: API) {
  }

  createDefaultConfigWithAuthorization<D = any>(authUser?: AuthUser): AxiosAuthorizationConfig<D> {
    const config: AxiosAuthorizationConfig<D> = {
      headers: {
        Authorization: this.createAuthorizationHeader(authUser)
      }
    }

    return config;
  }

  createAuthorizationHeader(authUser?: AuthUser): string {
    if (!authUser) return '';

    return 'Bearer ' + authUser.token;
  }
}

export default BaseApi;
