import {FunctionComponent, PropsWithChildren} from 'react';
import './button-set.css';

const ButtonWrap: FunctionComponent<PropsWithChildren> = ({children}) => {
    return <div className="ButtonSet-item">{children}</div>;
};

const ButtonSet: FunctionComponent<PropsWithChildren> = ({children}) => {
    const buttons = Array.isArray(children) ?
        children.map(
            (child, ix) => child ? <ButtonWrap key={'nav-' + ix}>{child}</ButtonWrap> : null
        )
        :
        children;

    return <div className="pages-NavButtons">{buttons}</div>
};

export default ButtonSet;
