import config from '../config/config';

import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import DemoApi from './api/demo';
import AuthApi from './api/auth-api';
import OptionValuesApi from './api/option-values';
import ProfileApi from './api/profile-api';

/** @see https://medium.com/@enetoOlveda/how-to-use-axios-typescript-like-a-pro-7c882f71e34a */
export class API {
  private axios: AxiosInstance;

  constructor(private urlBase: string) {
    this.axios = axios.create({
      baseURL: urlBase
    });
  }

  config(config?: AxiosRequestConfig): AxiosRequestConfig {
    if (!config) config = {} as AxiosRequestConfig;
    if (!config.headers) config.headers = {};
    if (!config.headers['Access-Control-Allow-Origin']) config.headers['Access-Control-Allow-Origin'] = '*';
    if (!config.headers['Access-Control-Allow-Methods']) config.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';

    return config;
  }

  public get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.axios.get<T, R>(url, config);
  }

  public post<T, B = T, R = AxiosResponse<T>>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.axios.post(url, data, config);
  }

  // public delete<T, B = T, R = AxiosResponse<T>>(
  public delete<T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.axios.delete(url, config);
  }

  public put<T, B = T, R = AxiosResponse<T>>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.axios.put(url, data, config);
  }

  private enableDebug() {
    this.axios.interceptors.request.use(request => {
      //console.log('Starting Request', JSON.stringify(request, null, 2));
      console.log(
        'Sending request:',
        request.baseURL,
        request.url,
        request.method,
      );
      return request;
    });
    this.axios.interceptors.response.use(response => {
      console.log('Got response: ', response);
      return response;
    });
  }
}

type APIInstance = {
  auth: AuthApi
  demo: DemoApi
  optionValues: OptionValuesApi
  profile: ProfileApi
}

function api(): APIInstance {
  const a = new API(config.apiBase)
  return {
    auth: new AuthApi(a),
    demo: new DemoApi(a),
    optionValues: new OptionValuesApi(a),
    profile: new ProfileApi(a)
  };
}

export default api
