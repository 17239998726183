import React, {FunctionComponent, PropsWithChildren, useContext} from 'react';
import ModalContent from './modal-content';
import ButtonSet from '../buttons/button-set';
import Button, {ButtonStyle} from '../buttons/button';
import Modal from './modal';
import {useNavigate} from 'react-router-dom';
import AppContext from '../../contexts/app-context';
import './demo-select-confirmation-modal.css';
import selectVoiceImage from '../../assets/img/select-voice.svg';
type DemoSelectConfirmationModalProps = PropsWithChildren;

const DemoSelectConfirmationModal: FunctionComponent<DemoSelectConfirmationModalProps> = () => {
    const appContext = useContext(AppContext);
    const nav = useNavigate();

    if (!appContext.authUser) return null;

    return (
        <Modal className="DemoSelectConfirmationModal">
            <ModalContent>
                <img src={selectVoiceImage} width={32} alt="Select Voice"/>
                <p>Hi {appContext.authUser.name}!</p>
                <p>Your selection has been saved.</p>
                <p>You can go to your profile and view it at any time.</p>
                <ButtonSet>
                    <Button onClick={() => {
                        nav('/profile', {state: {showSaves: true}});
                        appContext.modalManager.popAll();
                    }}
                            style={ButtonStyle.Primary}>
                        View Profile
                    </Button>

                    <Button onClick={() => {
                        appContext.modalManager.popAll();
                    }}
                            style={ButtonStyle.Danger}>
                        Close
                    </Button>
                </ButtonSet>
            </ModalContent>
        </Modal>
    );
}

export default DemoSelectConfirmationModal;
