import {createContext} from 'react';
import Theme from '../enums/theme';
import PlayerService from '../services/player-service';

interface PlayerContextType {
    theme: Theme
    service: PlayerService
}

const PlayerContext = createContext<PlayerContextType>({
    service: new PlayerService(),
    theme: Theme.Dark
});

export default PlayerContext;
