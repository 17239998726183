import React, {FunctionComponent, PropsWithChildren, useRef} from 'react';
import classNames from 'classnames';
import './button.css';

enum ButtonStyle {
    Plain = 'Plain',
    Primary = 'Primary',
    Default = 'Default',
    Link = 'Link',
    Danger = 'Danger',
    Hollow = 'Hollow',
    ProfileVoices = 'ProfileVoices'
}

export type ButtonProps = PropsWithChildren<{
    onClick: () => void
    onHover?: () => void
    onHoverOut?: () => void
    style?: ButtonStyle
    variation?: string
    disabled?: boolean
}>;
// Enabled,
//   Disabled,
//   Hover,
//   // Focus,
//   Pressed
const Button: FunctionComponent<ButtonProps> = ({
                                                    children,
                                                    disabled,
                                                    onClick,
                                                    onHover,
                                                    onHoverOut,
                                                    style,
                                                    variation
                                                }) => {
    const ref = useRef<number>();
    // De-bounce mouse events
    function handleHoverOver() {
        if (!onHover) return;
        if (ref.current) window.clearTimeout(ref.current);
        ref.current = window.setTimeout(() => {
            onHover();
        }, 50);
    }
    function handleHoverOut() {
        if (!onHoverOut) return;
        if (ref.current) window.clearTimeout(ref.current);
        ref.current = window.setTimeout(() => {
            onHoverOut();
        }, 50);
    }

    return (
        <div onClick={ev => {
            ev.preventDefault();
            ev.stopPropagation();
            if (onClick && !disabled) onClick();
        }}
             onMouseOver={() => handleHoverOver()}
             onMouseOut={() => handleHoverOut()}
             className={classNames(
                 'Button',
                 {Default: style === undefined},
                 {Disabled: disabled},
                 style,
                 variation
             )}
        >
            {children}
        </div>
    )
};

export default Button;
export {ButtonStyle}
