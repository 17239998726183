import React, {FunctionComponent} from 'react';
import ProgressBar from './progress-bar';
import './controls.css';
import RewindButton from './rewind-button';
import FastForwardButton from './fastforward-button';
import PlayButton from './play-button';
import ShuffleButton from './shuffle-button';
import LoopButton from './loop-button';
import SelectVoiceButton from '../buttons/select-voice-button';
import ShareButton from '../buttons/share-button';

type ControlsProps = {
    isPlaying: boolean
    percent: number
    onFastFowardClicked: () => void
    onLoopClicked?: () => void
    onPlayClicked: () => void
    onRewindClicked: () => void
    onSelectVoice?: () => void
    onShare?: () => void
    onShuffleClicked?: () => void
};

const Controls: FunctionComponent<ControlsProps> = ({
                                                        percent,
                                                        isPlaying,
                                                        onFastFowardClicked,
                                                        onLoopClicked,
                                                        onPlayClicked,
                                                        onSelectVoice,
                                                        onShare,
                                                        onRewindClicked,
                                                        onShuffleClicked,
                                                    }) => {
    return (
        <div className={'player-Controls'}>
            <ProgressBar percent={percent}/>
            <div className="player-Controls-main">
                {onShuffleClicked ? (
                    <div className="player-Controls-shuffle">
                        <ShuffleButton onClick={onShuffleClicked}/>
                    </div>
                ) : null}
                {onLoopClicked ? (
                    <div className="player-Controls-loop">
                        <LoopButton onClick={onLoopClicked}/>
                    </div>
                ) : null}
                <div className="player-Controls-primary">
                    {onSelectVoice ? (
                        <div className="player-Controls-select-voice">
                            <SelectVoiceButton onClick={onSelectVoice} showText={false}/>
                        </div>
                    ) : null}
                    <RewindButton onClick={onRewindClicked}/>
                    <div className="player-Controls-play">
                        <PlayButton isPlaying={isPlaying} onClick={onPlayClicked}/>
                    </div>
                    <FastForwardButton onClick={onFastFowardClicked}/>
                    {onShare ? (
                        <div className="player-Controls-share">
                            <ShareButton onClick={onShare}/>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Controls;
