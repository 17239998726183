import React, {FunctionComponent, useState} from 'react';
import selectVoiceImage from '../../assets/img/select-voice.svg';
import Button, {ButtonStyle} from './button';
import './select-voice-button.css';
import classNames from 'classnames';

type SelectVoiceButtonProps = {
    onClick: () => void
    showText?: boolean
    saved?: boolean
};

const SelectVoiceButton: FunctionComponent<SelectVoiceButtonProps> = ({onClick, saved, showText}) => {
    if (showText === undefined) showText = true;
    const [animating, setAnimating] = useState<boolean>(false);
    return (
        <Button onClick={() => {
            onClick();
            setAnimating(true);
            setTimeout(() => {
                setAnimating(false);
            }, 650);
        }} style={ButtonStyle.Plain} >
            <div className="flex SelectVoiceButton">
                <img src={selectVoiceImage} height={30} alt="Share" className={classNames('SelectVoiceButton-img', {spin: animating, saved})}/>
                {showText ? (
                    <div>{saved === true ?
                        <>Remove<br/>Voice</>
                        :
                        <>Select<br/>Voice</>
                    }</div>
                ) : null}
            </div>
        </Button>
    );
};

export default SelectVoiceButton;
