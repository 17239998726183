import React, {FunctionComponent, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import AppContext from '../contexts/app-context';
import {Demo} from '../models/demo';
import LoadingIndicator from '../components/common/loading-indicator';
import Player from '../components/player/player';

type DemoPageProps = PropsWithChildren;

type DemoPageNavParams = {
    demoId: string
}

const DemoPage: FunctionComponent<DemoPageProps> = () => {
    const [demo, setDemo] = useState<Demo | undefined>();
    const navParams = useParams<DemoPageNavParams>();
    const appContext = useContext(AppContext);
    useEffect(() => {
        if (navParams.demoId?.length === 0) return;
        const demoId = parseInt(navParams.demoId ? navParams.demoId : '');
        if (isNaN(demoId)) return;

        appContext.demoService?.getDemo(demoId, appContext.authUser).then(demo => {
            if (!demo) {
                setDemo(demo);
                return;
            }
            appContext.playerService.enqueue(demo);
            setDemo(demo);
        });

    }, [appContext.authUser, navParams.demoId, appContext.demoService, appContext.playerService])
    return (
        <div className="container">
            <div style={{textAlign: 'center', color: '#fff'}}>
                <main>
                    {demo ? (
                        <>
                            <Player service={appContext.playerService}
                                    onToggleLike={() => {
                                    }}
                            />
                        </>
                    ) : (
                        <><LoadingIndicator/> Loading demo</>
                    )}
                </main>
            </div>
        </div>
    );
}

export default DemoPage;
