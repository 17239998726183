import {FunctionComponent} from 'react';
import './waves.css';
import classNames from 'classnames';
import {useLocation} from 'react-router-dom';

const Waves: FunctionComponent = () => {
    let opaque = false;
    const location = useLocation();
    if (location.pathname === '/') opaque = true;
    return (
        <div className={classNames('pages-Waves', {opaque: opaque === true})}>
            <img src="/assets/img/waves-color.svg" alt=""/>
        </div>
    );
};

export default Waves;
