import {AppContextType} from '../../contexts/app-context';
import {Demo} from '../../models/demo';
import LoginRegisterModal from '../../components/auth/login-register-modal';
import React from 'react';

function handleToggleDemoLike(appContext: AppContextType, demo: Demo) {
    // User must login first
    if (appContext.authUser === undefined) {
        appContext.modalManager.pushModal(
            <LoginRegisterModal login={authUser => {
                appContext.setAuthUser(authUser);
                appContext.modalManager.popModal();
                /**
                 * Assume that the user meant to LIKE this demo since it
                 * would not have been toggled on when they were logged out
                 */
                appContext.demoService?.likeDemo(authUser, demo.id, true);
            }}/>
        );
    } else {
        appContext.demoService?.likeDemo(appContext.authUser!, demo.id, !demo.doesLike);
    }
}

export default handleToggleDemoLike;
