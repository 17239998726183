import {ServerResponseDemos} from '../../types/server-response-demos';
import AuthUser from '../../models/auth-user';
import BaseApi from './base';
import {ServerResponseDemo} from '../../types/server-response-demo';
import {DemoSearchCriteria} from '../../types/demo-search-criteria';
import {Demo} from '../../models/demo';
import {ServerRequestDemo} from '../../types/server-request-demo';
import {AxiosError, AxiosResponse} from 'axios';
import {ServerResponseSelectVoice} from '../../types/server-response-select-voice';

type DemoSearchData = {
    gender?: number
    age?: number
    voice?: number
};

class DemoApi extends BaseApi {

    getDemo(demoId: number, authUser?: AuthUser): Promise<ServerResponseDemo> {
        const config = authUser ? this.createDefaultConfigWithAuthorization(authUser) : {};

        return this.api.get<{ demo: ServerResponseDemo }>('/demos/' + demoId, config).then(response => response.data.demo);
    }

    save(authUser: AuthUser, demo: ServerRequestDemo): Promise<ServerResponseDemo> {
        const config = this.createDefaultConfigWithAuthorization(authUser);

        const verb = demo.id ? 'put' : 'post';
        let path = '/account/demos';
        if (demo.id) path += '/' + demo.id;

        return this.api[verb]<{ demo: ServerResponseDemo }, ServerRequestDemo>(path, demo, config).then(response => response.data.demo);
    }

    getDemos(criteria: DemoSearchCriteria, authUser?: AuthUser, limit?: number, offset?: number): Promise<ServerResponseDemos> {
        const config = this.createDefaultConfigWithAuthorization<DemoSearchData>(authUser);

        // Add search criteria to data
        config.params = {};
        if (criteria.gender) config.params.gender = criteria.gender.id;
        if (criteria.age) config.params.age = criteria.age.id;
        if (criteria.voiceType) config.params.voice = criteria.voiceType.id;
        if (limit) config.params.limit = limit;
        if (offset) config.params.offset = offset;

        return this.api.get<ServerResponseDemos>('/demos', config).then(response => response.data);
    }

    getSaves(authUser: AuthUser, limit?: number, offset?: number): Promise<ServerResponseDemos> {
        const config = this.createDefaultConfigWithAuthorization(authUser);

        return this.api.get<ServerResponseDemos>('/demos/my-saves', config).then(response => response.data);
    }

    markLiked(authUser: AuthUser, demoId: number, doesLike: boolean): Promise<void> {
        const config = this.createDefaultConfigWithAuthorization(authUser);
        const endPoint = '/demos/' + demoId + '/like';

        let promise;
        if (doesLike) promise = this.api.post(endPoint, {}, config);
        else promise = this.api.delete(endPoint, config);

        return promise.then(() => {
        });
    }

    toggleLike(authUser: AuthUser, demoId: number): Promise<Demo> {
        const config = this.createDefaultConfigWithAuthorization(authUser);

        return this.api.post<Demo, null>('/demos/' + demoId + '/like/toggle', null, config).then(response => response.data);
    }

    markPlayed(demoId: number, authUser?: AuthUser): Promise<void> {
        const config = this.createDefaultConfigWithAuthorization(authUser);

        return this.api.post('/demos/' + demoId + '/play', null, config);
    }

    selectVoice(authUser: AuthUser, demoId: number): Promise<ServerResponseSelectVoice> {
        const config = this.createDefaultConfigWithAuthorization(authUser);

        return this.api.post<ServerResponseSelectVoice, null>('/demos/' + demoId + '/save', null, config).then(response => {
            return response.data;
        });
    }

    deselectVoice(authUser: AuthUser, demoId: number): Promise<ServerResponseSelectVoice> {
        const config = this.createDefaultConfigWithAuthorization(authUser);

        return this.api.delete<null, AxiosResponse<ServerResponseSelectVoice>>('/demos/' + demoId + '/save', config).then(response => {
            return response.data;
        });
    }

    uploadAudio(authUser: AuthUser, demoId: number, file: File, onProgress?: UploadProgress): Promise<ServerResponseDemo> {
        const config = this.createDefaultConfigWithAuthorization(authUser);
        config.headers['Content-Type'] = 'multipart/form-data';
        const data = new FormData();
        data.append('audiofile', file);
        if (onProgress) {
            config.onUploadProgress = (ev) => {
                onProgress(ev.loaded, ev.total);
            };
        }

        return this.api
            .post<{ demo: ServerResponseDemo }, FormData>('/account/demos/' + demoId + '/audio', data, config)
            .then(response => response.data.demo);
    }

    share(authUser: AuthUser, demoId: number, method: string, value: string): Promise<ShareResponse> {
        const config = this.createDefaultConfigWithAuthorization(authUser);
        return this.api.post<null, { method: string, value: string }, AxiosResponse<ShareResponse>>('/demos/' + demoId + '/share', {
            method,
            value
        }, config)
            .then(response => response.data)
            .catch(e => {
                if (e instanceof AxiosError && (e.response?.status === 400 || e.response?.status === 401)) {
                    console.log('Error:', e);
                    return {
                        success: false,
                        errors: e.response?.data.errors ? e.response.data.errors : ['Unknown errors']
                    }
                } else {
                    return {
                        success: false,
                        errors: ['Unknown errors']
                    }
                }

            });
    }

    getSaveCount(authUser?: AuthUser): Promise<number> {
        const config = this.createDefaultConfigWithAuthorization(authUser)
        return this.api.get<{count: number}>('/demos/my-save-count', config)
            .then(response => response.data.count);
    }
    // getLoggedInStatus(): Promise<LoginStatus> {
    //   return this.api.get<AxiosResponse<LoginStatus>>('/home/logincheck').then(response => {
    //     // console.log('RESPONSE: ', response.data);
    //     return response.data;
    //   });
    // }
}

type ShareResponse = {
    success: boolean
    errors?: Array<string>
};

export type UploadProgress = (sizeUploaded: number, totalSize: number) => void;

export default DemoApi;
