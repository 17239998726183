export type CacheKey = string | number;

type CacheStorage<T> = {
  [key: string]: T
};

export type ObjectCacheSubscriber = (key: string) => void

class ObjectCache<T> {
  private cachedObjects: CacheStorage<T> = {};
  private subscribers: Array<ObjectCacheSubscriber> = [];

  set(key: CacheKey, obj: T) {
    key = ObjectCache.normalizeKey(key);
    this.cachedObjects[key] = obj;
    this.notifySubscribers(key);
  }

  get(key: CacheKey): T | undefined {
    key = ObjectCache.normalizeKey(key);
    return this.cachedObjects[key];
  }

  has(key: CacheKey): boolean {
    key = ObjectCache.normalizeKey(key);
    return this.cachedObjects[key] !== undefined;
  }

  del(key: CacheKey): void {
    key = ObjectCache.normalizeKey(key);
    if (this.has(key)) {
      delete this.cachedObjects[key];
      this.notifySubscribers(key);
    }
  }

  keys(): Array<string> {
    return Object.keys(this.cachedObjects);
  }


  subscribe(subscriber: ObjectCacheSubscriber) {
    this.subscribers.push(subscriber);
  }

  unsubscribe(subscriber: ObjectCacheSubscriber) {
    this.subscribers = this.subscribers.filter(sub => sub !== subscriber);
  }

  public static normalizeKey(key: CacheKey): string {
    if (typeof key === 'number') key = '' + key;
    return key;
  }
  /**
   * Check all subscribers to see if they are listening for this key change, then update accordingly
   * @param key
   * @private
   */
  private notifySubscribers(key: CacheKey) {
    const normalizedKey = ObjectCache.normalizeKey(key);
    this.subscribers.forEach(subscriber => {
      subscriber(normalizedKey);
    });
  }
}
export default ObjectCache;
