import {FunctionComponent} from 'react';
import './hamburger.css';

type HamburgerProps = {
    onClick: () => void
};

const Hamburger: FunctionComponent<HamburgerProps> = ({onClick}) => {
    return (
        <div className="Hamburger" onClick={() => onClick()}></div>
    );
}

export default Hamburger;
