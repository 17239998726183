import AuthUser from '../models/auth-user';
import {AppContextType} from '../contexts/app-context';
import LoginRegisterModal from '../components/auth/login-register-modal';
import {ModalOptions} from '../services/modal-manager';

function requireUser(appContext: AppContextType): Promise<AuthUser> {
    if (appContext.authUser) return Promise.resolve(appContext.authUser);

    return new Promise((resolve, reject) => {
        const options = new ModalOptions();
        options.onClose(() => {
            return; // never resolve
            // reject(new Error('User did not register'));
        });

        appContext.modalManager.pushModal(
            (
                <LoginRegisterModal login={authUser => {
                    options.onClose(undefined);
                    appContext.modalManager.popModal();
                    resolve(authUser)
                }}/>
            ),
            options
        );
    });
}

function requireLoggedInUser(appContext: AppContextType): Promise<AuthUser> {
    return requireUser(appContext).then(authUser => {
        if (authUser !== appContext.authUser) appContext.setAuthUser(authUser);
        return authUser;
    });
}

export {requireLoggedInUser};
