import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Demo} from '../models/demo';
import AppContext from '../contexts/app-context';
import {AutoPlay, DemoSearchCriteria} from '../types/demo-search-criteria';
import OptionValueButton from '../components/buttons/option-value-button';
import api from '../services/api';
import OptionValue from '../models/option-value';
import ButtonSet from '../components/buttons/button-set';
import {useLocation} from 'react-router-dom';
import LoadingIndicator from '../components/common/loading-indicator';
import handleLoggedOut from '../services/handle-logged-out';
import DemoList from '../components/demo-list/list';
import Player from '../components/player/player';
import prevButtonImage from '../assets/img/previous.svg';
import nextButtonImage from '../assets/img/next.svg';
import {SubscribableResults} from '../services/demo-service';
import {PlayerStatusEvent} from '../types/player-status-event';
import classNames from 'classnames';
import Button from '../components/buttons/button';
import {requireLoggedInUser} from '../utils/users';
import ShareDemoModal from '../components/modals/share-demo-modal';
import './search.css';
import handleSelectVoice from '../services/ui/handle-select-voice';
import handleToggleDemoLike from '../services/ui/handle-toggle-demo-like';
import handleShareDemo from '../services/ui/handle-share-demo';
import {DemoResultState} from '../types/demo-result-state';

type OptionsState = {
    voiceType?: Array<OptionValue>
    genders?: Array<OptionValue>
    ages?: Array<OptionValue>
}

const SearchPage: FunctionComponent = (props) => {
    const location = useLocation();
    const locationState = location.state as { voiceType?: OptionValue, showSaves?: boolean };

    const [demoResult, setDemoResult] = useState<DemoResultState | undefined>();
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [playingDemoAtIndex, setPlayingDemoAtIndex] = useState<number>(-1);
    const [search, setSearch] = useState<DemoSearchCriteria>({
        autoPlay: AutoPlay.Disabled,
        offset: 0,
        voiceType: locationState && locationState.voiceType ? locationState.voiceType : undefined,
        gender: undefined,
        age: undefined,
        limit: undefined
    });
    const [options, setOptions] = useState<OptionsState>({});

    const appContext = useContext(AppContext);

    // Update voiceType search criteria based on "location state"
    useEffect(() => {
        setSearch(prevSearch => prepareSearchState({
            ...prevSearch,
            voiceType: locationState && locationState.voiceType ? locationState.voiceType : undefined
        }));
    }, [locationState]);

    // Search demos
    useEffect(() => {
        if (!appContext.demoService) return;
        setDemoResult(undefined);
        let demoSubscription: SubscribableResults<Demo>;

        function handleDemoResults() {
            // console.log('handleDemoResults Update:', demoSubscription.results);
            setDemoResult((prevState) => {
                return prevState ? {...prevState, results: demoSubscription.results} : undefined
            });
        }

        let getDemosPromise: Promise<SubscribableResults<Demo>>;

        if (locationState.showSaves === true) {
            if (!appContext.authUser) return;
            getDemosPromise = appContext.demoService.getSaves(appContext.authUser, search.limit, search.offset);
        } else {
            getDemosPromise = appContext.demoService.getDemos(search, appContext.authUser, search.limit, search.offset);
        }

        getDemosPromise.then(subscription => {
            if (demoSubscription) demoSubscription.unsubscribe(handleDemoResults); // Unsubscribe and re-subscribe
            // const demos: Array<Demo> = demoIds.map<Demo>(demoId => demoService.getCachedDemo(demoId)!).filter(demo => demo !== undefined);
            demoSubscription = subscription;
            demoSubscription.subscribe(handleDemoResults);

            let playAtIx: number = -1;
            if (search.autoPlay === AutoPlay.Last) playAtIx = subscription.results.length - 1;
            else if (search.autoPlay === AutoPlay.First) playAtIx = 0;

            if (playAtIx >= 0) {
                appContext.playerService.enqueueAndPlay(subscription.results[playAtIx]);
                setPlayingDemoAtIndex(playAtIx);
            }

            setDemoResult({
                results: subscription.results,
                limit: subscription.limit,
                offset: subscription.offset,
                total: subscription.total
            });
        }).catch(e => {
            handleLoggedOut(e, appContext.modalManager, appContext.setAuthUser, appContext.authUser);
        });
        return () => {
            if (demoSubscription) demoSubscription.unsubscribe(handleDemoResults);
        }
    }, [
        appContext.demoService,
        appContext.authUser,
        appContext.playerService,
        appContext.setAuthUser,
        appContext.modalManager,
        locationState.showSaves,
        search
    ]);

    // Player status
    useEffect(() => {
        function handlePlayerStatus(ev: PlayerStatusEvent) {
            setIsPlaying(ev.isPlaying);
            let ix = -1;
            let debug = false;
            if (ev.current && demoResult !== undefined && demoResult.results.length > 0) {
                debug = true;
                ix = demoResult.results.findIndex(demo => demo.id === ev.current!.id);
            }
            console.log('Received player status:', ev.isPlaying, ix, ev.current, debug, demoResult?.results.length);
            setPlayingDemoAtIndex(ix);
        }

        appContext.playerService.getStatus(handlePlayerStatus);
        return () => {
            appContext.playerService.removeOnStatusChanged(handlePlayerStatus);
        }
    }, [appContext.playerService, demoResult]);

    // Initial load
    useEffect(() => {
        api().optionValues.getValues(['gender', 'age-group']).then(results => {
            setOptions({
                ages: results['age-group'],
                genders: results['gender']
            })
        });
    }, []);

    return (
        <div className="container">
            <div className={classNames('page-Search', {playing: isPlaying})}>
                <header className="row">
                    <div style={{fontSize: 40, color: '#fff', fontWeight: 'bold', marginRight: 40}}>
                        {locationState.showSaves === true ? 'MY SAVES' : (
                            <>
                                {search.voiceType === undefined ? 'ALL' : search.voiceType.value.toUpperCase()}<br/>
                                VOICES
                            </>
                        )}
                    </div>
                    {locationState.showSaves === true ? null : (
                        <>
                            <ButtonSet>
                                <OptionValueButton options={options.genders}
                                                   noOptionText="Any"
                                                   onSelect={value => {
                                                       setSearch(prevState => prepareSearchState({
                                                           ...prevState,
                                                           offset: 0,
                                                           gender: value
                                                       }));
                                                   }}
                                >
                                    {search.gender === undefined ? 'Gender' : search.gender.value}
                                </OptionValueButton>
                                <OptionValueButton options={options.ages}
                                                   noOptionText="Any"
                                                   onSelect={value => {
                                                       setSearch(prevState => prepareSearchState({
                                                           ...prevState,
                                                           offset: 0,
                                                           age: value
                                                       }));
                                                   }}
                                >
                                    {search.age === undefined ? 'Age' : search.age.value}
                                </OptionValueButton>
                            </ButtonSet>
                        </>
                    )}
                </header>

                <main>
                    <div className="page-Search-songnav">
                        <NavigateSongImage disabled={!demoResult || search.offset < demoResult.limit}
                                           src={prevButtonImage}
                                           onClick={() => {
                                               if (!demoResult) return;
                                               let changeOffset = search.offset - demoResult.limit;
                                               if (changeOffset < 0) changeOffset = 0;
                                               setSearch(prevSearch => prepareSearchState({
                                                   ...prevSearch,
                                                   offset: changeOffset
                                               }));
                                           }}/>
                    </div>

                    <div className="page-Search-demos">
                        {demoResult ? (
                            demoResult.results.length > 0 ? (
                                <>
                                    <DemoList demos={demoResult.results}
                                              onSelectAtIndex={ix => {
                                                  appContext.playerService.enqueue(demoResult.results[ix]);
                                                  appContext.playerService.play();
                                              }}
                                              onToggleLikeAtIndex={(ix) => {
                                                  handleToggleDemoLike(appContext, demoResult.results[ix]);
                                              }}
                                              onSelectVoiceAtIndex={ix => {
                                                  handleSelectVoice(appContext, demoResult.results[ix]);
                                              }}
                                              onShareAtIndex={ix => {
                                                  handleShareDemo(appContext, demoResult.results[ix]);
                                              }}
                                    />
                                    {/*Displaying {demoResult.offset + 1}-{demoResult.offset + Math.min(demoResult.limit, demoResult.results.length)} of {demoResult.total} results*/}
                                </>
                            ) : (
                                <>No results matched your search.</>
                            )
                        ) : (
                            <div style={{color: '#fff'}}><LoadingIndicator/> Loading</div>
                        )}
                    </div>
                    <div className="page-Search-player">
                        {/* Only visible when playing and < 1024px */}
                        <div className="page-Search-player-stop">
                            <Button onClick={() => {
                                appContext.playerService.stop();
                            }}>Close</Button>
                        </div>
                        <Player service={appContext.playerService}
                                onRewindClicked={() => {
                                    if (!demoResult) return;
                                    if (playingDemoAtIndex > 0) { // Play previous result on this page
                                        appContext.playerService.enqueueAndPlay(demoResult.results[playingDemoAtIndex - 1]);
                                        return;
                                    } else if (demoResult.offset > 0) { // Load the previous page and prepare the last track to play
                                        let newOffset = demoResult.offset - demoResult.limit;
                                        if (newOffset < 0) newOffset = 0;
                                        setSearch(prevState => prepareSearchState({
                                            ...prevState,
                                            offset: newOffset
                                        }, AutoPlay.Last))
                                        return;
                                    }
                                }}
                                onFastFowardClicked={() => {
                                    if (!demoResult) return;

                                    let changeIndex = playingDemoAtIndex + 1;

                                    if (changeIndex >= 0 && changeIndex <= demoResult.results.length - 1) { // Play next result on this page
                                        appContext.playerService.enqueueAndPlay(demoResult.results[changeIndex]);
                                        return;
                                    } else if (changeIndex >= demoResult.results.length - 1 && (demoResult.offset + demoResult.limit <= demoResult.total - 1)) { // Load the next page and play the first result
                                        setSearch(prevSearch => prepareSearchState({
                                            ...prevSearch,
                                            offset: demoResult.offset + demoResult.limit
                                        }, AutoPlay.First));
                                        return;
                                    }
                                }}
                                onShare={appContext.playerService.getStatus().current ? () => {
                                    requireLoggedInUser(appContext)
                                        .then(authUser => {
                                            appContext.modalManager.pushModal(
                                                <ShareDemoModal demo={appContext.playerService.getStatus().current!}
                                                                onDone={() => {
                                                                    appContext.modalManager.popModal();
                                                                }}
                                                />
                                            )
                                        })
                                        .catch(() => {
                                        })
                                } : undefined}
                                onSelectVoice={appContext.playerService.getStatus().current ? () => {
                                    const demo: Demo = appContext.playerService.getStatus().current!;
                                    handleSelectVoice(appContext, demo);
                                } : undefined}
                                onToggleLike={(demo) => {
                                    handleToggleDemoLike(appContext, demo);
                                }}
                        />
                    </div>

                    <div className="page-Search-songnav">
                        <NavigateSongImage
                            disabled={!demoResult || search.offset >= demoResult.total - demoResult.limit}
                            src={nextButtonImage}
                            onClick={() => {
                                if (!demoResult) return;
                                setSearch(prevSearch => prepareSearchState({
                                    ...prevSearch,
                                    offset: search.offset + demoResult.limit
                                }));
                            }}/>
                    </div>
                </main>
            </div>
        </div>
    );
}

type NavigateSongImageProps = {
    disabled: boolean
    src: string
    onClick: () => void
}
const NavigateSongImage: FunctionComponent<NavigateSongImageProps> = ({
                                                                          disabled,
                                                                          onClick,
                                                                          src
                                                                      }) => {
    return <div style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        fontWeight: 'bold',
        color: '#fff',
        opacity: disabled ? 0.5 : 1
    }}
                onClick={() => {
                    if (disabled) return;
                    onClick()
                }}>
        <img src={src} style={{height: 90}} alt="Change Page"/>
    </div>
};

function prepareSearchState(state: DemoSearchCriteria, autoPlay: AutoPlay = AutoPlay.Disabled): DemoSearchCriteria {
    return {
        ...state,
        autoPlay
    };
}

export default SearchPage;
