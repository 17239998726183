import {FunctionComponent, useEffect, useState} from 'react';
import {ButtonStyle} from './button';
import OptionValue from '../../models/option-value';
import OptionValueButton from './option-value-button';
import OptionValuesService from '../../services/option-values-service';
import {useNavigate} from 'react-router-dom';
import './profile-voices-button.css';

type ProfileVoicesButtonProps = {};
const ProfileVoicesButton: FunctionComponent<ProfileVoicesButtonProps> = () => {
    const [options, setOptions] = useState<Array<OptionValue> | undefined>();
    const nav = useNavigate();

    useEffect(() => {
        const service = new OptionValuesService();
        service.getValues(['voice-type']).then(values => {
            setOptions(values['voice-type'])
        });
    }, []);

    return <OptionValueButton buttonStyle={ButtonStyle.ProfileVoices}
                              options={options}
                              className="ProfileVoiceButton"
                              showIndicator={false}
                              onSelect={value => {
                                  nav('/search', {
                                      state: {
                                          voiceType: value
                                      }
                                  })
                              }}>
        <PlayCircle/>
        Profile Voices
    </OptionValueButton>
};

const PlayCircle: FunctionComponent = () => (
    <div className="ProfileVoiceButton-play"></div>
);

export default ProfileVoicesButton;
