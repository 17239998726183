import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import './home.css';
import HomePageTemplate from './home-template';

const HomePageNoAudio: FunctionComponent = () => {
    return <HomePageTemplate>
        <video preload="auto" autoPlay muted className="video-bg">
            <source src="/assets/videos/intro.web" type="video/webm"/>
            <source src="/assets/videos/intro.mp4" type="video/mp4"/>
        </video>
    </HomePageTemplate>
}

export default HomePageNoAudio;
