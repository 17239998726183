import {ServerResponseAuth} from '../../types/server-response-auth';
import {AxiosError} from 'axios';
import BaseApi from './base';
import {LoginStatus} from '../../types/login-status';
import AuthUser from '../../models/auth-user';
import {ServerResponseSuccess} from '../../types/server-response-success';

type RegistrationData = {
    email: string
    password: string
    name: string
    phone: string
}

type AuthenticationData = {
    email: string
    password: string
}

class AuthApi extends BaseApi {
    checkStatus(authUser: AuthUser): Promise<LoginStatus> {
        const config = this.createDefaultConfigWithAuthorization(authUser);

        return this.api.get<LoginStatus>('/auth/check', config).then(result => result.data);
    }

    register(email: string, password: string, name: string, phone: string): Promise<ServerResponseAuth> {
        return this.api.post<ServerResponseAuth, RegistrationData>('/auth/register', {
            email,
            password,
            name,
            phone
        })
            .then(result => result.data)
            .catch((e: AxiosError) => {
                if (e instanceof AxiosError && e.response && e.response.status === 400) {
                    return e.response.data;
                }
                console.log('Did not catch error: ', e);
                throw e;
            });
    }

    authenticate(email: string, password: string): Promise<ServerResponseAuth> {
        return this.api.post<ServerResponseAuth, AuthenticationData>('/auth/login', {
            email,
            password
        })
            .then(result => result.data)
            .catch((e: AxiosError) => {
                if (e instanceof AxiosError && e.response && e.response.status === 400) {
                    return e.response.data;
                }
                throw e;
            });
    }

    requestPasswordReset(email: string): Promise<ServerResponseSuccess> {
        return this.api.post<ServerResponseSuccess, {email: string}>('/auth/requestresetpassword', {email}).then(response => response.data);
    }

    resetPassword(email: string, code: string, password: string): Promise<ServerResponseSuccess> {
        return this.api.post<ServerResponseSuccess, {email: string, code: string, password: string}>('/auth/resetpassword', {email, code, password}).then(response => response.data);
    }
}

export default AuthApi;
