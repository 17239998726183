import React, {FunctionComponent} from 'react';
import './error-list.css';

type ErrorListProps = {
  errors: Array<string>;
};

const ErrorList: FunctionComponent<ErrorListProps> = ({errors}) => {
  if (errors.length === 0) return null;
  return (
    <div className="ErrorList">
      {/*The following error(s) occurred:*/}
      <ul>
        {errors.map(error => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorList;
