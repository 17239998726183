import React, {FunctionComponent} from 'react';
import './like-button.css';
import InactiveLikeImage from '../../assets/img/like.svg';
import LikeImage from '../../assets/img/like-active.svg';
type LikeButtonProps = {
    doesLike: boolean
    onClick: () => void
};

const LikeButton: FunctionComponent<LikeButtonProps> = ({doesLike, onClick}) => {
    return (
        <div className="player-LikeButton" onClick={() => onClick()}>
            <img src={doesLike ? LikeImage : InactiveLikeImage} alt="Like Album" style={{width: 30}}/>
        </div>
    );
};

export default LikeButton;
