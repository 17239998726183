import {User} from '../models/user';
import ObjectCache from './object-cache';

class UserService {
  private static _instance: UserService;
  private cache: ObjectCache<User> = new ObjectCache<User>();

  getUser(id: number): Promise<User | undefined> {
    return Promise.resolve(this.cache.get(id));
  }

  getCachedUser(id: number): User | undefined {
    return this.cache.get(id);
  }

  public store(user: User) {
    this.cache.set(user.id, user);
  }

  static instance(): UserService {
    if (this._instance === undefined) {
      this._instance = new UserService();
    }
    return this._instance;
  }
}

export default UserService;
