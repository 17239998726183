import React, {FunctionComponent} from 'react';
import './play-button.css';
import playDarkImage from '../../assets/img/play-dark.svg';
import pauseDarkImage from '../../assets/img/pause-dark.svg';
import classNames from 'classnames';

type PlayButtonProps = {
  isPlaying: boolean
  onClick: () => void
};

const PlayButton: FunctionComponent<PlayButtonProps> = ({isPlaying, onClick}) => {
    const img = isPlaying ? pauseDarkImage : playDarkImage;
    return (
        <div className={classNames('player-PlayButton', {playing: isPlaying})} onClick={() => onClick()}>
          <img src={img} alt="Play Button" />
        </div>
    );
};

export default PlayButton;
