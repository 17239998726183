import React, {FunctionComponent} from 'react';
import playDarkImage from '../../assets/img/play-dark.svg';
import Button, {ButtonStyle} from './button';

type PlayButtonProps = {
    onClick: () => void
};

const PlayButton: FunctionComponent<PlayButtonProps> = ({onClick}) => {
    return (
        <Button onClick={onClick} style={ButtonStyle.Plain}>
            <img src={playDarkImage} height={40} alt="Play Button"/>
        </Button>
    );
};

export default PlayButton;
