import React, {FunctionComponent} from 'react';

type RingProps = {
    borderColor?: string
    borderWidth?: number | string
    fillColor?: string
    size: number
    innerSize?: number
}
const Ring: FunctionComponent<RingProps> = ({
                                                borderColor,
                                                borderWidth,
                                                fillColor,
                                                innerSize,
                                                size
                                            }) => {
    if (!fillColor) fillColor = '#000';
    if (!innerSize) innerSize = 0;

    return (
        <div style={{width: size, height: size}}>
            <svg viewBox={'0 0 ' + size + ' ' + size}>
                <defs>
                    <mask id={'mask-' + size}>
                        <rect width="100%" height="100%" fill="white"/>
                        <circle cx={size/2} cy={size/2} r={innerSize/2}/>
                    </mask>
                </defs>
                <circle cx={size/2}
                        cy={size/2}
                        r={size/2}
                        mask={'url(#mask-' + size + ')'}
                        fill={fillColor} style={{
                    // strokeWidth: 0.1,
                    // strokeLinecap: 'round',
                    // strokeLinejoin: 'round',
                    // strokeMiterlimit: 10,
                    // strokeDasharray: 'none',
                    // strokeOpacity: 1,
                }}/>
            </svg>
        </div>
    );
    //
    // const style: CSSProperties = {
    //     width: size,
    //     height: size
    // };
    //
    // if (borderColor) {
    //     if (!borderWidth) borderWidth = 1;
    //     style.border = borderWidth + ' solid ' + borderColor;
    // }
    //
    // return <div className="pages-Ring" style={style}></div>;
};

export default Ring;
