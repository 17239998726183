import {FunctionComponent, useContext, useState} from 'react';
import Button, {ButtonStyle} from '../buttons/button';
import TextInput from '../form/text-input';
import Modal from './modal';
import {Demo} from '../../models/demo';
import ModalContent from './modal-content';
import AppContext from '../../contexts/app-context';
import ButtonSet from '../buttons/button-set';
import ErrorList from '../common/error-list';
import api from '../../services/api';
import LoadingIndicator from '../common/loading-indicator';
import './share-demo-modal.css';

type ShareDemoModalProps = {
    demo: Demo
    // share: (method: string, value: string) => void
    onDone: () => void
}

enum SendingState {
    Normal,
    Saving,
    Done
}

const ShareDemoModal: FunctionComponent<ShareDemoModalProps> = ({
                                                                    demo,
                                                                    onDone
                                                                }) => {
    const [email, setEmail] = useState<string>('');
    const [errors, setErrors] = useState<Array<string>>([]);
    const [state, setState] = useState<SendingState>(SendingState.Normal);
    const appContext = useContext(AppContext);

    if (!appContext.authUser) {
        alert('You must be logged in to share');
        onDone();
        return <></>;
    }

    let view;

    switch (state) {
        case SendingState.Done:
            view = (
                <>
                    <p>Thank you for sharing!</p>
                    <Button style={ButtonStyle.Danger}
                            onClick={() => {
                                onDone();
                            }}
                    >
                        Close
                    </Button>
                </>
            );
            break;
        case SendingState.Saving:
        case SendingState.Normal:
        default:
            view = (
                <>
                    <ErrorList errors={errors}/>
                    {state === SendingState.Saving ? (
                        <><div className="saving"><LoadingIndicator/></div> Sharing...</>
                    ) : (
                        <>
                            <p>Email: <TextInput value={email} onChange={val => setEmail(val)}/></p>
                            <ButtonSet>
                                <Button onClick={() => {
                                    setErrors([]);
                                    setState(SendingState.Saving);
                                    api().demo.share(appContext.authUser!, demo.id, 'email', email)
                                        .then(result => {
                                            if (result.success) {
                                                setState(SendingState.Done);
                                            } else {
                                                setState(SendingState.Normal)
                                                setErrors(result.errors ?? ['Unknown form error']);
                                            }
                                        })
                                        .catch(e => {
                                            alert('Sorry, an unknown error occurred');
                                            setState(SendingState.Normal);
                                            onDone();
                                        });
                                }} style={ButtonStyle.Primary} disabled={email.length === 0}>Share</Button>
                                <Button style={ButtonStyle.Danger}
                                        onClick={() => {
                                            onDone();
                                        }}
                                >Cancel</Button>
                            </ButtonSet>
                        </>
                    )}
                </>
            );
            break;
    }
    return (
        <Modal title={'Share Demo: ' + demo.name} className="ShareDemoModal">
            <ModalContent>
                {view}
            </ModalContent>
        </Modal>
    );
}

export default ShareDemoModal;
