import React, {FunctionComponent} from 'react';
import './footer.css';
import SocialIcon from './social-icon';
import config from '../../config/config';

type FooterProps = {};

const Footer: FunctionComponent<FooterProps> = () => {
    return (
        <div className="container">
            <div className="Footer">
                <div className="Footer-social">
                    <SocialIcon src={'/assets/img/social/facebook.svg'}
                                href={config.social.facebookUrl}
                                alt="Facebook"/>
                    <SocialIcon src={'/assets/img/social/email.svg'}
                                href={'mailto:' + config.contactEmail}
                                alt="Email"/>
                    <SocialIcon src={'/assets/img/social/linkedin.svg'}
                                href={config.social.instagram}
                                alt="Instagram"/>
                    <SocialIcon src={'/assets/img/social/phone.svg'}
                                href={config.social.whatsApp}
                                alt="Phone"/>
                </div>
            </div>
        </div>
    );
};

export default Footer;
