import React, {FunctionComponent, PropsWithChildren, useContext, useEffect, useState} from 'react';
import AppContext from '../../contexts/app-context';
import {SubscribableResults} from '../../services/demo-service';
import {Demo} from '../../models/demo';
import {DemoResultState} from '../../types/demo-result-state';
import update from 'immutability-helper';
import DemoList from '../../components/demo-list/list';
import handleToggleDemoLike from '../../services/ui/handle-toggle-demo-like';
import handleSelectVoice from '../../services/ui/handle-select-voice';
import handleShareDemo from '../../services/ui/handle-share-demo';
import LoadingIndicator, {IndicatorSize} from '../../components/common/loading-indicator';
import api from '../../services/api';
import Button, {ButtonStyle} from '../../components/buttons/button';

import './account-home.css';
import classNames from 'classnames';
import {Profile} from '../../types/profile';
import Player from '../../components/player/player';
import TextInput from '../../components/form/text-input';
import PhoneInput from '../../components/form/phone-input';
import {AxiosError} from 'axios';
import ErrorList from '../../components/common/error-list';
import PhotoUploader from '../../components/demo-upload/photo-uploader';
import DelayedTasker from '../../utils/delayed-tasker';

type AccountHomePageProps = PropsWithChildren;

const AccountHomePage: FunctionComponent<AccountHomePageProps> = (props) => {
    const [profile, setProfile] = useState<Profile | undefined>();
    const [demoResult, setDemoResult] = useState<DemoResultState | undefined>();
    const [savingProfile, setSavingProfile] = useState<{isSaving: boolean, errors: Array<string>}>({isSaving: false, errors: []});
    const appContext = useContext(AppContext);

    // Retrieve saves
    useEffect(() => {
        if (!appContext.demoService || !appContext.authUser) return;
        let demoSubscription: SubscribableResults<Demo>;

        function handleDemoResults() {
            setDemoResult((prevState) => {
                return prevState ? {...prevState, results: demoSubscription.results} : undefined
            });
        }

        // Retrieve profile
        api().profile.getOwnProfile(appContext.authUser, false).then(result => {
            // Check for profile-image in local storage
            const localImage = window.localStorage.getItem('profile-image');

            setProfile({
                age: result.profile.age,
                gender: result.profile.gender,
                name: appContext.authUser?.name,
                username: appContext.authUser ? appContext.authUser.username : '',
                voice: result.profile.voice,
                phone: result.profile.phone,
                photo: result.photo === null && localImage !== null ? localImage : result.photo
            });
        });

        // Retrieve saved demos
        appContext.demoService.getSaves(appContext.authUser).then(subscription => {
            if (demoSubscription) demoSubscription.unsubscribe(handleDemoResults);
            demoSubscription = subscription;
            demoSubscription.subscribe(handleDemoResults);
            setDemoResult({
                results: subscription.results,
                limit: subscription.limit,
                offset: subscription.offset,
                total: subscription.total
            });
        });
    }, [appContext.demoService, appContext.authUser]);

    if (!appContext.authUser) return <div className="container">You must be logged in to view this page.</div>

    return (
        <div className="container m-t">
            <div className="account-home">
                <div className={classNames('account-home-profile', {'profile-loading': profile === undefined})}>
                    <PhotoUploader onFileReady={file => {
                        const reader = new FileReader();
                        reader.onload = ev => {
                            if (!ev.target || typeof(ev.target.result) !== 'string') return;
                            // Store file result in local storage while it is being processed on the server
                            window.localStorage.setItem('profile-image', ev.target.result);
                            setProfile({
                                ...profile,
                                photo: ev.target.result
                            });
                        };
                        reader.readAsDataURL(file);
                    }}>
                        <>
                            <div className="account-home-photo-container">
                                <div className="account-home-photo"
                                     style={{backgroundImage: profile && profile.photo ? 'url(' + profile.photo + ')' : ''}}></div>
                            </div>
                            <div className="text-center">
                                <div className="account-home-photo-button" onClick={() => {}}>Upload Photo</div>
                            </div>
                        </>

                    </PhotoUploader>

                    <ErrorList errors={savingProfile.errors}/>
                    <ul className="account-home-profile-meta">
                        <li>{appContext.authUser.name}</li>
                    {profile && <>
                            <li>
                                <TextInput value={profile.name ? profile.name : ''}
                                           valid={profile.name !== undefined && profile.name.length > 0}
                                           placeholder="Your name"
                                           disabled={savingProfile.isSaving}
                                           onChange={val => {
                                               setProfile(update(profile, {
                                                   name: {$set: val}
                                               }));
                                           }}/>
                            </li>
                            <li>
                                <PhoneInput value={profile.phone ? profile.phone : ''}
                                            valid={profile.phone !== undefined && profile.phone.length > 0}
                                            placeholder="Your phone"
                                            disabled={savingProfile.isSaving}
                                            onChange={val => {
                                               setProfile(update(profile, {
                                                   phone: {$set: val}
                                               }));
                                           }}/>
                            </li>
                            <li>
                                <TextInput value={profile.username ? profile.username : ''}
                                           placeholder="Your email"
                                           disabled={savingProfile.isSaving}
                                           onChange={val => {
                                               setProfile(update(profile, {
                                                   username: {$set: val}
                                               }));
                                           }}/>
                            </li>
                        </>}
                    </ul>
                    {profile && <>
                        <Button style={ButtonStyle.Primary}
                                disabled={savingProfile.isSaving}
                                onClick={() => {
                                    if (!appContext.authUser) return;
                                    setSavingProfile({isSaving: true, errors: []});
                                    const delayedTasker = new DelayedTasker();

                                    api().profile.update(appContext.authUser, {
                                        name: profile.name,
                                        username: profile.username,
                                        phone: profile.phone
                                    }).then(() => {
                                        delayedTasker.doAfter(1000, () => {
                                            setSavingProfile({...savingProfile, isSaving: false});
                                        });
                                    }).catch(e => {
                                        console.log('Caught error: ', e.response);
                                        const errors = e instanceof AxiosError && e.response?.data ? e.response.data.errors : ['Unknown internal error'];
                                        setSavingProfile({isSaving: false, errors});
                                    });
                                }}>
                            {savingProfile.isSaving ? <>Saving</> : <>Update Profile</>}
                        </Button>
                        {savingProfile.isSaving && <LoadingIndicator size={IndicatorSize.small}/>}
                    </>}
                </div>
                <div className="account-home-divider"></div>
                <div className="account-home-voices">
                    <header>
                        <h1>My selected voices</h1>
                    </header>
                    <main>
                        <div className="account-home-demos">
                            {demoResult ? (
                                demoResult.results.length > 0 ? (
                                    <>
                                        <DemoList demos={demoResult.results}
                                                  onSelectAtIndex={ix => {
                                                      appContext.playerService.enqueueAndPlay(demoResult.results[ix]);
                                                  }}
                                                  onToggleLikeAtIndex={(ix) => {
                                                      handleToggleDemoLike(appContext, demoResult.results[ix]);
                                                  }}
                                                  onSelectVoiceAtIndex={ix => {
                                                      handleSelectVoice(appContext, demoResult.results[ix]);
                                                  }}
                                                  onShareAtIndex={ix => {
                                                      handleShareDemo(appContext, demoResult.results[ix]);
                                                  }}
                                        />
                                        {/*Displaying {demoResult.offset + 1}-{demoResult.offset + Math.min(demoResult.limit, demoResult.results.length)} of {demoResult.total} results*/}
                                    </>
                                ) : (
                                    <>No results matched your search.</>
                                )
                            ) : (
                                <div style={{color: '#fff'}}><LoadingIndicator/> Loading</div>
                            )}
                        </div>
                        <div className="account-home-player">
                            <Player service={appContext.playerService}/>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
}

export default AccountHomePage;
