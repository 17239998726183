import OptionValue from '../models/option-value';

export enum AutoPlay {
    Disabled= 'Disabled',
    First = 'First',
    Last = 'Last'
}

export type DemoSearchCriteria = {
    autoPlay: AutoPlay
    limit?: number
    offset: number
    voiceType?: OptionValue
    gender?: OptionValue
    age?: OptionValue
}