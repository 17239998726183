import React, {FunctionComponent} from 'react';
import './album-cover.css';
import LikeButton from './like-button';

type AlbumCoverProps = {
    image?: string
    label?: string
    doesLike: boolean
    onLike?: () => void
    size: number
};

const AlbumCover: FunctionComponent<AlbumCoverProps> = ({
                                                            image,
                                                            label,
                                                            doesLike,
                                                            onLike,
                                                            size
                                                        }) => {
    return (
        <div className="player-AlbumCover" style={{
            width: size,
            height: size,
            backgroundImage: 'url(' + image + ')'
        }}>
            {onLike ? (
                <div className="player-AlbumCover-like">
                    <LikeButton doesLike={doesLike}
                                onClick={onLike}/>
                </div>
            ) : null}
        </div>
    );
};

export default AlbumCover;
