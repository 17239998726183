import {FunctionComponent} from 'react';
import Theme from '../../enums/theme';

enum IndicatorSize {
    small,
    medium,
    large
}

type LoadingIndicatorProps = {
    size?: IndicatorSize
    theme?: Theme
};

const LoadingIndicator: FunctionComponent<LoadingIndicatorProps> = ({
                                                                        size,
                                                                        theme
                                                                    }) => {
    let src, useSize;
    switch (theme) {
        case Theme.Light:
            src = '/assets/img/loading-purple.svg';
            break;
        default:
            src = '/assets/img/loading-white.svg';
    }
    switch (size) {
        case IndicatorSize.large:
            useSize = 100;
            break;
        case IndicatorSize.small:
            useSize = 25;
            break;
        case IndicatorSize.medium:
        default:
            useSize = 50;
    }
    return <img src={src} style={{width: useSize, verticalAlign: 'middle'}} alt="Loading indicator"/>;
}

export default LoadingIndicator;
export {IndicatorSize}