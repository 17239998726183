import React, {FunctionComponent, useEffect, useState} from 'react';
import {NavigateFunction} from 'react-router-dom';
import Button, {ButtonStyle} from '../buttons/button';
import './main-navigation.css';
import OptionValue from '../../models/option-value';
import OptionValuesService from '../../services/option-values-service';
import ListOptions from '../common/list-options';
import ListOption from '../common/list-option';
import config from '../../config/config';

type MainNavigationProps = {
    nav: NavigateFunction
    saveCount?: number
};

// const Underline: FunctionComponent = () => (
//     <div style={{width: '70%', height: 2, backgroundColor: '#f4787e', margin: '0 auto'}}></div>
// );

enum DropdownMenu {
    Voices,
    Contact
}

const MainNavigation: FunctionComponent<MainNavigationProps> = ({nav, saveCount}) => {
    const [showDropdown, setShowDropdown] = useState<DropdownMenu | void>();
    const [voiceOptions, setVoiceOptions] = useState<Array<OptionValue> | undefined>();

    // @TODO Incorporate retrieval of options into main loading process
    useEffect(() => {
        const service = new OptionValuesService();
        service.getValues(['voice-type']).then(values => {
            setVoiceOptions(values['voice-type']);
        });
    }, []);

    function toggleMenu(menu: DropdownMenu) {
        setShowDropdown(menu === showDropdown ? undefined : menu);
        const handleBodyClick = (ev: Event) => {
            document.body.removeEventListener('click', handleBodyClick);
            setShowDropdown();
        }
        document.body.addEventListener('click', handleBodyClick);
    }

    return (
        <>
            {showDropdown === undefined ? null : (
                <div className="pages-MainNavigation-body" onClick={() => {
                }}>

                </div>
            )}
            <ul className="pages-MainNavigation">
                <li>
                    <Button style={ButtonStyle.Plain} variation="White" onClick={() => nav('/about')}>About Us</Button>
                    {/*<Underline/>*/}
                </li>
                <li>
                    {/*<Button style={ButtonStyle.Plain} variation="White"*/}
                    {/*        onClick={() => toggleMenu(DropdownMenu.Voices)}>{'Voices' + (saveCount === undefined ? '' : ' (' + commifyNumber(saveCount) + ')')}</Button>*/}
                    <Button style={ButtonStyle.Plain} variation="White"
                            onClick={() => toggleMenu(DropdownMenu.Voices)}>Voices</Button>
                    {/*<Underline/>*/}
                    {showDropdown === DropdownMenu.Voices ? (
                        <div className="pages-MainNavigation-dropdown">
                            {voiceOptions === undefined ?
                                'Loading'
                                :
                                <ListOptions>
                                    {
                                        voiceOptions.map(option => (
                                            <ListOption key={option.id} onClick={() => {
                                                nav('/search', {state: {voiceType: option}});
                                                setShowDropdown(undefined);
                                            }}>
                                                {option.value}
                                            </ListOption>
                                        ))
                                    }
                                </ListOptions>
                            }
                        </div>
                    ) : null}
                </li>
                {/*<li>*/}
                {/*    <Button style={ButtonStyle.Plain} variation="White" onClick={() => nav('/jobs')}>Our Jobs</Button>*/}
                {/*    /!*<Underline/>*!/*/}
                {/*</li>*/}
                <li>
                    <Button style={ButtonStyle.Plain} variation="White"
                            onClick={() => toggleMenu(DropdownMenu.Contact)}>Contact Us</Button>
                    {/*<Underline/>*/}
                    {showDropdown === DropdownMenu.Contact ? (
                        <div className="pages-MainNavigation-dropdown">
                            <ListOptions>
                                <ListOption>
                                    <a href={'mailto:' + config.contactEmail}
                                       onClick={() => setShowDropdown()}>{config.contactEmail}</a>
                                </ListOption>
                                <ListOption>
                                    <a href={'tel:' + config.contactPhone}
                                       onClick={() => setShowDropdown()}>{config.contactPhone}</a>
                                </ListOption>
                                <ListOption>
                                    <a href={config.social.whatsApp}><img src={'/assets/img/social/phone.svg'}
                                                                          style={{width: 30, verticalAlign: 'middle'}}
                                                                          alt="Phone icon"/></a> Whatsapp
                                </ListOption>
                            </ListOptions>
                        </div>
                    ) : null}
                </li>
            </ul>
        </>
    );
};

export default MainNavigation;
