import {createElement} from 'react';
import {AxiosError} from 'axios';
import LoginModal from '../components/auth/login-modal';
import AuthUser from '../models/auth-user';
import ModalManager from './modal-manager';

/**
 * Handle "unauthorized user" errors where the user is logged in (i.e. session has expired)
 * @param e
 * @param appContext
 */
const handleLoggedOut = (e: any, modalManager: ModalManager, setAuthUser: (authUser?: AuthUser) => void, authUser?: AuthUser) => {
    if (e instanceof AxiosError) {
        if (e.response?.status === 401 && authUser) {
            setAuthUser(); // Log user out
            modalManager.pushModal(
                createElement(LoginModal, {
                    login: authUser => {
                        setAuthUser(authUser);
                        modalManager.popModal();
                    }
                })
            )
        }
    }
}

export default handleLoggedOut