import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import LikeButton from '../buttons/like-button';
import PlayButton from '../buttons/play-button';
import {Demo} from '../../models/demo';
import './row.css';
import {commifyNumber} from '../../utils/strings';
import AppContext from '../../contexts/app-context';
import {PlayerStatusEvent} from '../../types/player-status-event';
import classNames from 'classnames';
import ShareButton from '../buttons/share-button';
import SelectVoiceButton from '../buttons/select-voice-button';

type SongRowProps = {
    demo: Demo
    onSelect: () => void
    onToggleLike?: () => void
    onSelectVoice: () => void
    onShare: () => void
};

const SongRow: FunctionComponent<SongRowProps> = ({
                                                      demo,
                                                      onShare,
                                                      onSelectVoice,
                                                      onSelect,
                                                      onToggleLike,
                                                  }) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const appContext = useContext(AppContext);

    useEffect(() => {
        function handleStatusChanged(ev: PlayerStatusEvent) {
            setIsPlaying(ev.current?.id === demo.id);
        }

        handleStatusChanged(appContext.playerService.getStatus(handleStatusChanged));
        return () => {
            appContext.playerService.removeOnStatusChanged(handleStatusChanged);
        };
    }, [appContext.playerService, demo.id]);

    // const cachedUser = appContext.userService?.getCachedUser(demo.userId);

    return <div className={classNames('song-list-Row', {playing: isPlaying})}>
        <div className="song-list-Row-info" onClick={() => onSelect()}>
            <div className="song-list-Row-name">
                {demo.name}
            </div>
            {/*<ButtonSet>*/}
            {/*    <Button onClick={() => {}} style={ButtonStyle.Hollow}>*/}
            {/*        <span className="song-list-Row-button">*/}
            {/*            <img src={selectVoiceImage} alt="Share"/>*/}
            {/*            <span className="label">Share</span>*/}
            {/*        </span>*/}
            {/*    </Button>*/}
            {/*    <Button onClick={() => {}} style={ButtonStyle.Hollow}>*/}
            {/*        <span className="song-list-Row-button">*/}
            {/*            <img src={shareImage} alt="Share"/>*/}
            {/*            <span className="label">Contact</span>*/}
            {/*        </span>*/}
            {/*    </Button>*/}
            {/*</ButtonSet>*/}
            {/*{cachedUser ? (*/}
            {/*    <div className="song-list-Row-user">Demo by {cachedUser.name}</div>*/}
            {/*) : null}*/}
        </div>
        <div className="song-list-Row-likes">{commifyNumber(demo.likes)} Likes</div>
        <ul className="song-list-Row-actions">
            {onToggleLike && <li>
                <LikeButton liked={demo.doesLike}
                            onClick={() => {
                                onToggleLike();
                            }}/>
            </li>}

            <li className="play-button">
                <PlayButton onClick={() => onSelect()}/>
            </li>

            <li>
                <ShareButton onClick={() => onShare()}/>
            </li>

            <li>
                <SelectVoiceButton onClick={() => onSelectVoice()} saved={demo.saved}/>
            </li>
            {/*<div className="song-list-Row-action">*/}
            {/*    <VerticalDotsButton onClick={() => {*/}
            {/*    }}/>*/}
            {/*</div>*/}
        </ul>
    </div>
};

export default SongRow;
