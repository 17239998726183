import React, {FunctionComponent, ReactEventHandler, useEffect, useRef, useState} from 'react';
import './home.css';
import HomePageTemplate from './home-template';

const HomePageAutoControls: FunctionComponent = () => {
    const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const toggleVideoPlaying = () => {
        if (isVideoPlaying) videoRef.current?.pause();
        else videoRef.current?.play();
        setIsVideoPlaying(!isVideoPlaying);
    }

    const resetVideo = () => {
        if (videoRef.current !== null) videoRef.current.currentTime = 0;
    }

    const handleTimeUpdate: ReactEventHandler<HTMLVideoElement> = (ev) => {
        if (ev.currentTarget.currentTime >= ev.currentTarget.duration) {

        }
    };

    // Make sure state is up-to-date with status
    useEffect(() => {
        if (videoRef.current?.paused && isVideoPlaying) setIsVideoPlaying(false);
        else if (videoRef.current !== null && !videoRef.current.paused && !isVideoPlaying) setIsVideoPlaying(true);
    }, [isVideoPlaying, videoRef.current?.paused]);

    return <HomePageTemplate>
        <video ref={videoRef} preload="auto" autoPlay className="video-bg" onClick={() => toggleVideoPlaying()} onTimeUpdate={handleTimeUpdate}>
            <source src="/assets/videos/intro.web" type="video/webm"/>
            <source src="/assets/videos/intro.mp4" type="video/mp4"/>
        </video>

        <div className="video-hero-controls">
            {isVideoPlaying && <svg fill="#fff" viewBox="0 0 24 24" width="100px" height="100px" onClick={() => resetVideo()}>
                <path d="M 2 2 L 4.9394531 4.9394531 C 3.1262684 6.7482143 2 9.2427079 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 6.486 17.514 2 12 2 L 12 4 C 16.411 4 20 7.589 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 9.7940092 4.9004767 7.7972757 6.3496094 6.3496094 L 9 9 L 9 2 L 2 2 z"/>
            </svg>}
            {!isVideoPlaying &&
                <svg viewBox="0 0 100 100" width="100" onClick={() => toggleVideoPlaying()}>
                    <circle cx="50" cy="50" r="47.5" fill="transparent" strokeWidth="5" stroke="#fff"/>
                    <polygon points="35,25 75,50 35,75" fill="#fff"/>
                </svg>}
            {/* Pause */}
            {isVideoPlaying &&
            <svg viewBox="0 0 100 100" width="100" onClick={() => toggleVideoPlaying()}>
                <circle cx="50" cy="50" r="47.5" fill="transparent" strokeWidth="5" stroke="#fff"/>
                <line x1={40} y1={20} x2={40} y2={80} stroke="#fff" strokeWidth="10"/>
                <line x1={60} y1={20} x2={60} y2={80} stroke="#fff" strokeWidth="10"/>
            </svg>}
        </div>
    </HomePageTemplate>
}

export default HomePageAutoControls;
