import React, {FunctionComponent, PropsWithChildren, useEffect, useRef, useState} from 'react';
import transcriptImage from '../assets/img/home/btn-transcript.svg';
import translationImage from '../assets/img/home/btn-translation.svg';
import audioProductionImage from '../assets/img/home/btn-audioprod.svg';
import voiceoverImage from '../assets/img/home/btn-voiceover.svg';
import './home.css';
import PlayButton from '../components/buttons/play-button';

const serviceImageWidth = 120;

const HomePageTemplate: FunctionComponent<PropsWithChildren> = ({children}) => {
    return <div style={{marginTop: 25}}>
        <div className="video-hero">
            <div className="video-container">
                {children}
            </div>
        </div>
        <div className="container">
            <div className="home-services">
                <h2>Our Services</h2>
                <div className="home-service-list">
                    <img src={transcriptImage} style={{width: serviceImageWidth}} alt="Transcription"/>
                    <img src={translationImage} style={{width: serviceImageWidth}} alt="Translation"/>
                    <img src={voiceoverImage} style={{width: serviceImageWidth}} alt="Voice Over"/>
                    <img src={audioProductionImage} style={{width: serviceImageWidth}} alt="Audio Production"/>
                </div>
            </div>
        </div>
    </div>
}

export default HomePageTemplate;
