import {FunctionComponent, ReactNode} from 'react';
import useWindowSize from '../../hooks/use/window-size';

type DotProps = {
    diameter: number
    left: number
    top: number
}

type DotsProps = {
    dotDiameter?: number
    spacing?: number
    offset?: number
}

const Dot: FunctionComponent<DotProps> = ({diameter, left, top}) => {
    return <div style={{
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
        position: 'absolute',
        left,
        top,
        borderRadius: '50%',
        width: diameter,
        height: diameter
    }}></div>
}

const Dots: FunctionComponent<DotsProps> = ({spacing, offset, dotDiameter}) => {
    if (!offset) offset = 50;
    if (!dotDiameter) dotDiameter = 3;
    if (!spacing) spacing = 100;

    const windowSize = useWindowSize();
    if (!windowSize.width || !windowSize.height) return null;

    const usableWidth = windowSize.width - offset;
    const usableHeight = windowSize.height - offset;

    let hDots = Math.floor(usableWidth / (dotDiameter + spacing));
    if (hDots * (dotDiameter + spacing) + dotDiameter < usableWidth) hDots++;

    let vDots = Math.floor(usableHeight / (dotDiameter + spacing));
    if (vDots * (dotDiameter + spacing) + dotDiameter < usableHeight) vDots++;

    const dots: Array<ReactNode> = [];

    for (let h=0; h < hDots; h++) {
        for (let v=0; v < vDots; v++) {
            const left = (h * (dotDiameter + spacing)) + offset;// - spacing + offset;
            const top = (v * (dotDiameter + spacing)) + offset; /* - spacing + offset;*/
            dots.push(<Dot key={'dot-' + v + '-' + h} diameter={dotDiameter} left={left} top={top}/>);
        }
    }

    return (
        <div>
            {dots}
        </div>
    );
}

export default Dots;