import {FunctionComponent, useContext, useState} from 'react';
import Modal from './modal';
import {Demo} from '../../models/demo';
import ModalContent from './modal-content';
import TextInput from '../form/text-input';
import CopyableText from '../common/copyable-text';

type ShareDemoModalProps = {
    demo: Demo
    // share: (method: string, value: string) => void
    onDone: () => void
}

const ShareDemoModal: FunctionComponent<ShareDemoModalProps> = ({
                                                                    demo,
                                                                    onDone
                                                                }) => {
    return (
        <Modal title={'Share Demo: ' + demo.name} className="ShareDemoModal">
            <ModalContent>
                {demo.name}<br/>
                <CopyableText text={demo.shareUrl}/>
            </ModalContent>
        </Modal>
    );
}

export default ShareDemoModal;
