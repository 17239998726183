import ObjectCache, {CacheKey, ObjectCacheSubscriber} from './object-cache';
import {Demo} from '../models/demo';
import api from './api';
import UserService from './user-service';
import AuthUser from '../models/auth-user';
import {ServerResponseDemo} from '../types/server-response-demo';
import {DemoSearchCriteria} from '../types/demo-search-criteria';
import {ServerRequestDemo} from '../types/server-request-demo';
import {UploadProgress} from './api/demo';

export class SubscribableResults<T> {
    private onUpdateHandlers: Array<() => void> = [];
    private subscriber?: ObjectCacheSubscriber;

    constructor(private _resultIds: Array<CacheKey>,
                private _objectCache: ObjectCache<T>,
                private _limit: number,
                private _offset: number,
                private _total: number
                ) {
    }

    get resultIds(): Array<CacheKey> {
        return this._resultIds;
    }

    get limit() { return this._limit; }
    get offset() { return this._offset; }
    get total() { return this._total; }

    get results(): Array<T> {
        return this._resultIds
            .filter(id => this._objectCache.has(id))
            .map(id => this._objectCache.get(id)!);
    }

    subscribe(onUpdate: () => void) {
        // this.onUpdateHandlers.push(onUpdate);
        this.onUpdateHandlers.push(onUpdate);

        if (this.subscriber === undefined) {
            this.subscriber = key => {
                if (this.resultIds.findIndex(id => ObjectCache.normalizeKey(id) === key) >= 0) {
                    this.notifySubscribers();
                }
            }
            this._objectCache.subscribe(this.subscriber);
        }
    }

    unsubscribe(onUpdate: () => void) {
        this.onUpdateHandlers = this.onUpdateHandlers.filter(test => test !== onUpdate);
        if (this.onUpdateHandlers.length === 0 && this.subscriber) {
            this._objectCache.unsubscribe(this.subscriber);
        }
    }

    notifySubscribers() {
        this.onUpdateHandlers.forEach(handler => handler());
    }
}

type SaveCountChangedHandler = (count: number | undefined) => void;

class DemoService /*extends SubscribableService<DemoService, number, Demo>*/ {
    private static _instance: DemoService;
    private _cache: ObjectCache<Demo> = new ObjectCache<Demo>();
    private _saveCount: number | undefined;
    private _onSaveCountChangedHandler: Array<SaveCountChangedHandler> = [];

    get saveCount(): number | undefined {
        return this._saveCount;
    }

    set saveCount(saveCount: number | undefined)
    {
        this._saveCount = saveCount;
        this.triggerSaveCountChanged();
    }

    constructor(private userService: UserService) {
        // super();
    }

    getDemo(id: number, authUser?: AuthUser): Promise<Demo | undefined> {
        if (this._cache.has(id)) return Promise.resolve(this._cache.get(id));

        return api().demo.getDemo(id, authUser).then(result => {
            const demo = this.createDemoFromDemoResult(result);
            this.cache(demo);

            return demo;
        });
    }

    getCachedDemo(id: number): Demo | undefined {
        return this._cache.get(id);
    }

    getDemos(criteria: DemoSearchCriteria, authUser?: AuthUser, limit?: number, offset?: number): Promise<SubscribableResults<Demo>> {
        return api().demo.getDemos(criteria, authUser, limit, offset).then(results => {
            // Cache users
            results.users.forEach(user => this.userService.store(user));
            return new SubscribableResults<Demo>(
                results.demos.map(result => {
                    const demo = this.createDemoFromDemoResult(result);
                    this.cache(demo);
                    return demo.id;
                }),
                this._cache,
                results.limit,
                results.offset,
                results.total
            );
            // );
        });
    }

    getSaves(authUser: AuthUser, limit?: number, offset?: number): Promise<SubscribableResults<Demo>> {
        return api().demo.getSaves(authUser, limit, offset).then(results => {
            results.users.forEach(user => this.userService.store(user));

            return new SubscribableResults<Demo>(
                results.demos.map(result => {
                    const demo = this.createDemoFromDemoResult(result);
                    this.cache(demo);
                    return demo.id;
                }),
                this._cache,
                results.limit,
                results.offset,
                results.total
            );
        });
    }

    likeDemo(authUser: AuthUser, demoId: number, doesLike: boolean): Promise<void> {
        const demo = this.getCachedDemo(demoId);
        if (!demo) throw new Error('Unknown demo');

        const restoreDoesLike = demo.doesLike;
        const restoreLikes = demo.likes;

        demo.doesLike = doesLike;
        demo.likes = demo.likes + (doesLike ? 1 : -1);
        this.cache(demo);
        return api().demo.markLiked(authUser, demoId, doesLike)
            .catch(e => {
                demo.doesLike = restoreDoesLike;
                demo.likes = restoreLikes;
                this.cache(demo);
                throw e;
            });
    }

    toggleDemo(authUser: AuthUser, demoId: number): Promise<void> {
        const demo = this.getCachedDemo(demoId);
        if (!demo) throw new Error('Unknown demo');

        demo.doesLike = !demo.doesLike;
        this.cache(demo); // Update local cache so that all listeners are notified immediately
        return api().demo.toggleLike(authUser, demoId).then(updatedDemo => {
            // Re-update cache incase something has changed on the reload
            this.cache(updatedDemo);
        });
    }

    save(authUser: AuthUser, demo: Demo): Promise<Demo> {
        const saveDemo = this.createRequestDemoFromDemo(demo);
        return api().demo.save(authUser, saveDemo).then(result => {
            const savedDemo = this.createDemoFromDemoResult(result);
            this.cache(savedDemo);
            return savedDemo;
        });
    }

    /**
     *
     * @param authUser
     * @param demoId
     * @return {string} Shareable link
     */
    selectVoice(authUser: AuthUser, demoId: number): Promise<void> {
        const localDemo = this.getCachedDemo(demoId);
        if (localDemo !== undefined) {
            console.log('localDemo.saved.selectVoice1:', localDemo.saved);
            localDemo.saved = true;
            this.cache(localDemo);
        }
        return api().demo.selectVoice(authUser, demoId).then(result => {
            this.saveCount = result.count;
        });
    }

    deselectVoice(authUser: AuthUser, demoId: number): Promise<void> {
        const localDemo = this.getCachedDemo(demoId);
        if (localDemo !== undefined) {
            console.log('localDemo.saved.selectVoice2:', localDemo.saved);
            localDemo.saved = false;
            this.cache(localDemo);
        }
        return api().demo.deselectVoice(authUser, demoId).then(result => {
            this.saveCount = result.count;
        });
    }

    uploadAudio(authUser: AuthUser, demoId: number, file: File, onProgress?: UploadProgress): Promise<Demo> {
        return api().demo
            .uploadAudio(authUser, demoId, file, onProgress)
            .then(result => {
                const savedDemo = this.createDemoFromDemoResult(result);
                this.cache(savedDemo);
                return savedDemo;
            })
    }

    private createDemoFromDemoResult(result: ServerResponseDemo): Demo {
        return new Demo(result.name, result.id, result.likes, result.user_id, result.url, result.album_cover, result.does_like, result.saved, result.share_url);
    }

    private createRequestDemoFromDemo(demo: Demo): ServerRequestDemo {
        const saveableDemo: ServerRequestDemo = {
            name: demo.name
        };

        if (demo.id !== 0) saveableDemo.id = demo.id;

        return saveableDemo;
    }

    cache(demo: Demo) {
        this._cache.set(demo.id, demo); // Cache result for later retrieval
    }

    onSaveCountChanged(handler: SaveCountChangedHandler) {
        this._onSaveCountChangedHandler.push(handler);
    }

    // Adds handler and immediately calls it with the current count
    getSaveCount(handler: SaveCountChangedHandler) {
        this.onSaveCountChanged(handler);
        handler(this._saveCount);
    }

    removeOnSaveCountChanged(handler: SaveCountChangedHandler) {
        this._onSaveCountChangedHandler = this._onSaveCountChangedHandler.filter(tHandler => tHandler !== handler);
    }

    triggerSaveCountChanged() {
        this._onSaveCountChangedHandler.forEach(handler => handler(this._saveCount));
    }

    static instance(userService: UserService): DemoService {
        if (this._instance === undefined) {
            this._instance = new DemoService(userService);
        }
        return this._instance;
    }
}

export default DemoService;
