import React, {FunctionComponent} from 'react';
import './progress-bar.css';

type ProgressBarProps = {
  percent: number
};

const ProgressBar: FunctionComponent<ProgressBarProps> = ({percent}) => {
    return (
        <div className="player-Progress-Bar">
          <div className="player-Progress-Bar-fill" style={{width: percent + '%'}}>
            Progress Bar
          </div>
        </div>
    );
};

export default ProgressBar;
