import {FunctionComponent, PropsWithChildren} from 'react';
import './page-not-found.css';

type FourOhFourProps = PropsWithChildren;

const PageNotFound: FunctionComponent<FourOhFourProps> = (props) => {
    return (
        <div className="PageNotFound">
            <h1>Oh No!</h1>
            <p>It looks like the page you were looking for could not be found.</p>
        </div>
    );
}

export default PageNotFound;
