import React, {FunctionComponent, KeyboardEvent} from 'react';
import './text-input.css';
import classNames from 'classnames';

export type TextInputProps = {
    type?: string
    value: string
    disabled?: boolean
    onChange: (value: string) => void
    onKeyDown?: (ev: KeyboardEvent) => void
    autoComplete?: string
    placeholder?: string
    valid?: boolean
};

const TextInput: FunctionComponent<TextInputProps> = ({
                                                          autoComplete,
                                                          disabled,
                                                          onChange,
                                                          onKeyDown,
                                                          placeholder,
                                                          type,
                                                          valid,
                                                          value
                                                      }) => {
    if (!type) type = 'text';
    return (
        <div className={classNames('TextInput', {invalid: valid === false})}>
            <input type={type}
                   value={value}
                   placeholder={placeholder}
                   disabled={disabled}
                   autoComplete={autoComplete}
                   onChange={ev => onChange(ev.target.value)}
                   onClick={ev => ev.stopPropagation()}
                   onKeyDown={ev => {
                       if (onKeyDown) onKeyDown(ev);
                   }}
            />
        </div>
    );
};

export default TextInput;
