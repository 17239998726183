import {Demo} from '../models/demo';
import {PlayerStatusEvent} from '../types/player-status-event';
import {PlayerEventHandler} from '../types/player-status-handler';

// type PlayerStatus = {
//   demo?: Demo
// };

class PlayerService {
  private queue: Array<Demo> = [];
  private statusChangedHandlers: Array<PlayerEventHandler> = [];
  private queueIx: number = -1;
  private isPlaying: boolean = false;
  private lastPlayedId?: number;

  enqueue(demo: Demo) {
    this.queue = [demo];
    this.queueIx = this.queue.length - 1;
    this.triggerStatusChanged();
  }

  play() {
    if (this.queue.length > 0 && this.queueIx < 0) this.queueIx = 0;
    this.isPlaying = true;
    this.triggerStatusChanged();
  }

  enqueueAndPlay(demo: Demo) {
    this.enqueue(demo);
    this.play();
  }

  stop() {
    this.isPlaying = false;
    this.triggerStatusChanged();
  }

  private triggerStatusChanged() {
    this.statusChangedHandlers.forEach(handler => handler(this.createStatusEvent()));
  }

  private createStatusEvent(): PlayerStatusEvent {
    const status: PlayerStatusEvent = {
      service: this,
      current: this.queueIx >= 0 ? this.queue[this.queueIx] : undefined,
      isPlaying: this.isPlaying,
      didChangeTrack: this.isPlaying && this.queue[this.queueIx].id !== this.lastPlayedId
    };

    this.lastPlayedId = this.queueIx >= 0 ? this.queue[this.queueIx].id : undefined;

    return status;
  }

  getStatus(onStatusHandler?: PlayerEventHandler): PlayerStatusEvent {
    if (onStatusHandler) this.onStatusChanged(onStatusHandler);

    return this.createStatusEvent();
  }

  onStatusChanged(handler: PlayerEventHandler) {
    this.statusChangedHandlers.push(handler);
  }

  removeOnStatusChanged(handler: PlayerEventHandler) {
    this.statusChangedHandlers = this.statusChangedHandlers.filter(checkHandler => handler !== checkHandler);
  }
}

export default PlayerService;
