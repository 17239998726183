import {FunctionComponent} from 'react';
import './rings.css';
import Ring from './ring';
// import Ring from './ring';

const Rings: FunctionComponent = () => {
    // 301b90
    return (
        <div className="pages-Rings">
            <div className="pages-Rings-ring1">
                <Ring size={600} innerSize={300} fillColor="#301b90"/>
            </div>
            <div className="pages-Rings-ring2">
                <Ring size={1200} innerSize={900} fillColor="#301b90"/>
            </div>
            <div className="pages-Rings-ring3">
                <Ring size={1800} innerSize={1500} fillColor="#301b90"/>
            </div>
            <div className="pages-Rings-ring4">
                <Ring size={2400} innerSize={2100} fillColor="#301b90"/>
            </div>
            <div className="pages-Rings-ring5">
                <Ring size={3000} innerSize={2700} fillColor="#301b90"/>
            </div>
        </div>
    );
};

export default Rings;
