import BaseApi from './base';
import {AxiosRequestConfig} from 'axios';
import OptionValue from '../../models/option-value';

type OptionValueResponse = {
  [key: string]: Array<OptionValue>
};

class OptionValuesApi extends BaseApi {
  getValues(keys: Array<string>): Promise<OptionValueResponse> {
    if (keys.length === 0) throw new Error('Keys must contain at least one value');

    const config: AxiosRequestConfig = {
      params: {keys: keys.join(',')},
    };

    return this.api.get<OptionValueResponse>('/options', config).then(result => result.data);
  }
}

export default OptionValuesApi;
