// REACT_APP_API_BASE can be set in root .env.local file to override API URL, e.g. in JS Dev mode
const apiBase = process.env.REACT_APP_API_BASE ? process.env.REACT_APP_API_BASE : 'defaulturl';

const config = {
  apiBase,
  env: process.env.NODE_ENV,
  contactEmail: 'podcast@cynusgpacific.com',
  contactPhone: '+504 9396-9283',
  social: {
    facebookUrl: 'https://www.facebook.com/CygnusBPO/',
    instagram: 'https://www.instagram.com/cygnusbpo/?igshid=YmMyMTA2M2Y=',
    whatsApp: 'https://wa.me/message/NMP7MOSV24G7F1'
  },
  registration: {
    phoneRequired: false,
    emailRequired: true
  }
}

export default config;
