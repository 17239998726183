/**
 * Allows tasks to be called after a specified minimumElapsedTime, using the time of instantiation as the starting point.
 *
 * This might be used, for example, to display a message for a minimum amount of time during an API call to perform a save,
 * which might return immediately, or which might take longer to process.
 *
 * Example usage:
 * const delayedTask = new DelayedTask();
 * api.saveOperation().then(() => {
 * // If the save operation takes longer than 2000 milliseconds then this will be called immediately,
 * // otherwise it will wait the difference between the instantiation/start time and the minimum elapsed time
 *     delayedTask.doAfter(2000, () => {
 *         // Some operation after 2000 milliseconds
 *     });
 * });
 */

type TaskExecutor = () => void;

class DelayedTasker {
    private startTime: Date;
    constructor() {
        this.startTime = new Date();
    }

    /**
     * @param {number} minimumElapsedTimeMilliseconds
     * @param {TaskExecutor} callback
     */
    doAfter(minimumElapsedTimeMilliseconds: number, callback: TaskExecutor) {
        const elapsedMilliseconds = (new Date()).getTime() - this.startTime.getTime();

        if (elapsedMilliseconds > minimumElapsedTimeMilliseconds) { // Enough time has elapsed so run immediately
            callback();
        } else {
            window.setTimeout(callback, minimumElapsedTimeMilliseconds - elapsedMilliseconds); // Wait until the minimumElapsedTime has elapsed
        }
    }
}

export default DelayedTasker;
