import {FunctionComponent, PropsWithChildren} from 'react';
import './nav-buttons.css';

const NavButton: FunctionComponent<PropsWithChildren> = ({children}) => {
    return <div className="pages-NavButton">{children}</div>;
};

const NavButtons: FunctionComponent<PropsWithChildren> = ({children}) => {
    const buttons = Array.isArray(children) ?
        children.map((child, ix) => <NavButton key={'nav-' + ix}>{child}</NavButton>)
        :
        children;

    return <div className="pages-NavButtons">{buttons}</div>
};

export default NavButtons;
