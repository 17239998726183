import {createContext} from 'react';
import ModalManager from '../services/modal-manager';
import UserService from '../services/user-service';
import DemoService from '../services/demo-service';
import AuthUser from '../models/auth-user';
import PlayerService from '../services/player-service';

export type AppContextType = {
  modalManager: ModalManager,
  userService?: UserService,
  demoService?: DemoService
  authUser?: AuthUser
  playerService: PlayerService
  setAuthUser: (authUser?: AuthUser) => Promise<void>
};

const AppContext = createContext<AppContextType>({
  modalManager: new ModalManager(),
  playerService: new PlayerService(),
  setAuthUser: () => {
    console.error('setAuthUser must be overridden');
    return Promise.resolve()
  }
});

export default AppContext;
