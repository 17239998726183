import {requireLoggedInUser} from '../../utils/users';
import ShareDemoModal from '../../components/modals/share-demo-modal';
import React from 'react';
import {AppContextType} from '../../contexts/app-context';
import {Demo} from '../../models/demo';
import ShareDemoLinkModal from '../../components/modals/share-demo-link-modal';

function handleShareDemo(appContext: AppContextType, demo: Demo) {
    // requireLoggedInUser(appContext).then(authUser => {
        appContext.modalManager.pushModal(
            <ShareDemoLinkModal demo={demo}
                            onDone={() => {
                                appContext.modalManager.popModal();
                            }}
            />
        );
    // }).catch(() => {
    // });
}

export default handleShareDemo;
