import React, {FunctionComponent} from 'react';
import './loop-button.css';
import LoopImage_Dark from '../../assets/img/loop-dark.svg';
// import LoopImage_Light from '../../assets/img/loop-light.svg';
type LoopButtonProps = {
  onClick: () => void
};

const LoopButton: FunctionComponent<LoopButtonProps> = ({onClick}) => {
  return (
    <div className="player-LoopButton" onClick={onClick}>
      <img src={LoopImage_Dark} style={{width: 24}} alt="Loop Button"/>
    </div>
  );
};

export default LoopButton;
