import React, {FunctionComponent, PropsWithChildren, useEffect, useState} from 'react';
import './home.css';
import HomePageTemplate from './home-template';

type HomePageControlsProps = PropsWithChildren;

const HomePageControls: FunctionComponent<HomePageControlsProps> = (props) => {
    return (
        <HomePageTemplate>
            <video preload="auto" controls className="video-bg">
                <source src="/assets/videos/intro.web" type="video/webm"/>
                <source src="/assets/videos/intro.mp4" type="video/mp4"/>
            </video>
        </HomePageTemplate>
    );
}

export default HomePageControls;
