import ObjectCache from './object-cache';
import OptionValue from '../models/option-value';
import api from './api';
import {OptionValues} from '../types/option-values';

class OptionValuesService {
  // @TODO Caching of keys
  private cache = new ObjectCache<Array<OptionValue>>();
  // private keyRequest =
  getValues(keys: Array<string>): Promise<OptionValues> {
    // let uncachedKeys = keys.filter(key => this.cache.has(key));
    this.cache.del('temp');
    return api().optionValues.getValues(keys);
  }
}

export default OptionValuesService;
