import React, {FunctionComponent, useState} from 'react';
import Modal from '../modals/modal';
import ModalContent from '../modals/modal-content';
import TextInput from '../form/text-input';
import FormRow from '../form/form-row';
import Label from '../form/label';
import Button from '../buttons/button';
import ErrorList from '../common/error-list';
import api from '../../services/api';
import AuthUser from '../../models/auth-user';
import './modal.css';
import {UserLoginProps} from '../../types/user-login-props';
import SwitchLoginMethod from './switch-login-method';
import LoginMethod from '../../enums/login-method';

export type LoginModalProps = UserLoginProps

enum LoginState {
    Form,
    Processing,
    Success
}

const LoginModal: FunctionComponent<LoginModalProps> = ({login, switchLoginMethod}) => {
    const [loginState, setLoginState] = useState<LoginState>(LoginState.Form);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errors, setErrors] = useState<Array<string>>([]);

    function handleLogin() {
        const e = [];
        setErrors([]);

        if (email.length === 0) e.push('Email is required');
        else if (!email.match(/.+@.+\..+/)) e.push('Invalid email format');

        if (e.length === 0) {
            setLoginState(LoginState.Processing);
            api().auth.authenticate(email, password).then(auth => {
                if (auth.success) {
                    setLoginState(LoginState.Success);
                    setTimeout(function () {
                        const authUser = new AuthUser(email, auth.name!, auth.token!);
                        login(authUser);
                    }, 1000);
                } else {
                    setErrors(auth.messages);
                    setLoginState(LoginState.Form);
                }
            }).catch(e => {
                setErrors(['An unknown error occurred when communicating with the server.']);
                setLoginState(LoginState.Form);
            });
        } else {
            setErrors(e);
        }
    }

    let content = null;

    switch (loginState) {
        case LoginState.Success:
            content = <p style={{textAlign: 'center'}}>Success!</p>;
            break;

        case LoginState.Processing:
            content = <p style={{textAlign: 'center'}}>Processing</p>;
            break;

        case LoginState.Form:
        default:
            content = (
                <>
                    <h4>Login</h4>
                    <ErrorList errors={errors}/>
                    <form>
                        <FormRow>
                            <Label>Email:</Label>
                            <TextInput value={email}
                                       autoComplete="username"
                                       onChange={value => setEmail(value)}
                            />
                        </FormRow>
                        <FormRow>
                            <Label>Password:</Label>
                            <TextInput type="password"
                                       value={password}
                                       autoComplete="current-password"
                                       onChange={value => setPassword(value)}
                            />
                        </FormRow>
                        <div className="Modal-auth-actions">
                            <Button onClick={() => handleLogin()}>Login</Button>
                        </div>
                    </form>
                    {switchLoginMethod ?
                        <SwitchLoginMethod current={LoginMethod.Login} setMethod={switchLoginMethod}/> : null}
                </>
            );
    }

    return (
        <Modal className="Modal-auth Modal-auth-login">
            <ModalContent>
                {content}
            </ModalContent>
        </Modal>
    );
}

export default LoginModal;
