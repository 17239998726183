import {FunctionComponent, PropsWithChildren, useEffect, useRef, useState} from 'react';
import './copyable-text.css';
import CopyIcon from './copy-icon';
import classNames from 'classnames';

type CopyableTextProps = {text: string};

const CopyableText: FunctionComponent<CopyableTextProps> = ({text}) => {
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const timer = useRef<number>();

    const copyTextToClipboard = (delay: number = 0) => {
        if (timer.current) clearTimeout(timer.current);
        setShowConfirmation(true);

        timer.current = window.setTimeout(() => {
            setShowConfirmation(false);
        }, 1000);

        navigator.clipboard.writeText(text);
    }

    const handleClick = () => {
        copyTextToClipboard();
    };

    useEffect(() => {
        copyTextToClipboard();
    }, [text]);

    return (
        <div className="CopyableText" onClick={() => handleClick()}>
            {text} <CopyIcon onClick={handleClick}/>
            <div className={classNames('CopyableText-copied', {visible: showConfirmation})}>Copied</div>
        </div>
    );
}

export default CopyableText;
