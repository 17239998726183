import React, {FunctionComponent} from 'react';
import shareImage from '../../assets/img/share.svg';
import Button, {ButtonStyle} from './button';

type ShareButtonProps = {
    onClick: () => void
};

const ShareButton: FunctionComponent<ShareButtonProps> = ({onClick}) => {
    return (
        <Button onClick={onClick} style={ButtonStyle.Plain}>
            <img src={shareImage} height={30} alt="Share"/>
        </Button>
    );
};

export default ShareButton;
