import React, {Component, PropsWithChildren} from 'react';
import {
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
import AppContext from './contexts/app-context';

import ModalStack from './components/modals/modal-stack';

import DemoService from './services/demo-service';
import UserService from './services/user-service';
import ModalManager from './services/modal-manager';
import PlayerService from './services/player-service';

import SearchPage from './pages/search';
import HomePageNoAudio from './pages/home-no-audio';
import HomePageAutoControls from './pages/home-auto-controls';
import HomePageWithControls from './pages/home-controls';

import AuthUser from './models/auth-user';

import './App.css';
import AboutPage from './pages/about';
import Jobs from './pages/jobs';
import api from './services/api';
import {PlayerStatusEvent} from './types/player-status-event';
import Rings from './components/pages/rings';
import Waves from './components/pages/waves';
import Dots from './components/pages/dots';
import Header from './components/pages/header';
import Footer from './components/pages/footer';
import DemoPage from './pages/demos';
import PageNotFound from './pages/page-not-found';
import AccountHomePage from './pages/account/account-home';
import HomePageUnmute from './pages/home-unmute';

enum LoadStatus {
    LOADING,
    LOADED,
    FAILED
}

type AppState = {
    loadStatus: LoadStatus
    // modals: Array<Modal>
    authUser?: AuthUser
}

type AppProps = PropsWithChildren

class App extends Component<AppProps, AppState> {
    private modalManager: ModalManager = new ModalManager();
    private userService: UserService = new UserService();
    private demoService: DemoService = new DemoService(this.userService);
    private playerService: PlayerService = new PlayerService();

    constructor(props: AppProps) {
        super(props);

        this.state = {
            loadStatus: LoadStatus.LOADING,
            // modals: [],
            authUser: undefined
        };
    }

    componentDidMount() {
        //this.modalManager.onChange(this.handleModalChange.bind(this));
        this.playerService.onStatusChanged(this.handlePlayEvent.bind(this));
        if (this.state.loadStatus === LoadStatus.LOADING) {
            const token = localStorage.getItem('user.token');
            const name = localStorage.getItem('user.name');
            const username = localStorage.getItem('user.username');
            if (token === null || username === null || name === null) {
                this.setState({loadStatus: LoadStatus.LOADED});
            } else {
                const authUser = new AuthUser(username, name, token);
                // Verify that user session is still valid
                api().auth.checkStatus(authUser).then(status => {
                    if (status.loggedIn) {
                        this.demoService.saveCount = status.numSaves;
                        this.setState({
                            loadStatus: LoadStatus.LOADED,
                            authUser
                        });
                    } else {
                        localStorage.removeItem('user.token');
                        localStorage.removeItem('user.username');
                        localStorage.removeItem('user.name');
                        this.setState({
                            loadStatus: LoadStatus.LOADED
                        });
                    }
                }).catch(() => {
                    this.setState({
                        loadStatus: LoadStatus.FAILED
                    })
                });
            }
        }
    }

    handlePlayEvent(ev: PlayerStatusEvent) {
        if (ev.didChangeTrack && ev.isPlaying && ev.current) {
            api().demo.markPlayed(ev.current.id, this.state.authUser).then(() => {
                // console.log('Play Recorded');
            });
        }
    }

    componentWillUnmount() {
        // this.modalManager.removeOnChange(this.handleModalChange.bind(this));
        this.playerService.removeOnStatusChanged(this.handlePlayEvent.bind(this));
    }

    // handleModalChange(ev: StackChangeEvent) {
    //     this.setState({modals: ev.stack});
    // }

    render() {
        return (
            <AppContext.Provider value={{
                modalManager: this.modalManager,
                userService: this.userService,
                demoService: this.demoService,
                authUser: this.state.authUser,
                playerService: this.playerService,
                setAuthUser: (authUser?: AuthUser): Promise<void> => {
                    let promiseCount: () => Promise<number | undefined>;
                    if (authUser) {
                        localStorage.setItem('user.token', authUser.token);
                        localStorage.setItem('user.name', authUser.name);
                        localStorage.setItem('user.username', authUser.username);

                        promiseCount = () => api().demo.getSaveCount(authUser).then(count => {
                            return count;
                        });
                    } else {
                        localStorage.removeItem('user.token');
                        localStorage.removeItem('user.name');
                        localStorage.removeItem('user.username');
                        promiseCount = () => Promise.resolve(undefined);
                    }

                    return promiseCount().then(saveCount => {
                        this.demoService.saveCount = saveCount;
                        this.setState({authUser});
                    })
                }
            }}>
                {this.state.loadStatus === LoadStatus.LOADED ? (
                    <>
                        <Rings/>
                        <Waves/>
                        <Dots/>
                        <Header/>
                        <Routes>
                            <Route path="/" element={<HomePageUnmute/>} />
                            <Route path="/home-no-audio" element={<HomePageNoAudio/>} />
                            <Route path="/home-auto-controls" element={<HomePageAutoControls/>} />
                            <Route path="/home-with-controls" element={<HomePageWithControls/>} />
                            <Route path="/demos/:demoId" element={<DemoPage/>}/>
                            {/*<Route path="/account/saves" element={<SavesPage/>}/>*/}
                            <Route path="/search" element={<SearchPage/>}/>
                            <Route path="/profile" element={<AccountHomePage/>}/>
                            <Route path="/about" element={<AboutPage/>}/>
                            <Route path="/jobs" element={<Jobs/>}/>
                            <Route path="/page-not-found" element={<PageNotFound/>}/>
                            <Route path="/*" element={<Navigate to="/page-not-found"/>}/>
                        </Routes>
                        <Footer/>
                        <ModalStack modalManager={this.modalManager}/>
                    </>
                ) : (
                    <div className="loading">
                        <div className="loader">
                            <img src="/assets/img/logo.png" alt="Logo" id="loading-logo"/><br/>
                            {this.state.loadStatus === LoadStatus.FAILED ?
                                'Loading failed.  Please try again later'
                                :
                                <img src="/assets/img/loading-white.svg" alt="Loading" id="loader"/>
                            }
                        </div>
                    </div>
                )}
            </AppContext.Provider>
        );
    }
}

export default App;
