import React, {FunctionComponent, useState} from 'react';
import likeImage from '../../assets/img/like.svg';
import likeActiveImage from '../../assets/img/like-active.svg';
import './like-button.css';

type LikeButtonProps = {
    liked: boolean
    onClick: () => void
};

const LikeButton: FunctionComponent<LikeButtonProps> = ({liked, onClick}) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };
    const handleMouseOut = () => {
        setIsHovering(false);
    }

    const src = liked || isHovering ? likeActiveImage : likeImage;

    return (
        <div className="clickable LikeButton"
             onClick={() => onClick()}
             onMouseOver={() => handleMouseOver()}
             onMouseOut={() => handleMouseOut()}>
            <img src={src} height={40} alt="Like Button"/>
        </div>
    );
};

export default LikeButton;
