import React, {FunctionComponent} from 'react';
import shuffleDarkImage from '../../assets/img/shuffle-dark.svg';

import './shuffle-button.css';

type ShuffleButtonProps = {
  onClick: () => void
};

const ShuffleButton: FunctionComponent<ShuffleButtonProps> = ({onClick}) => {
    return (
        <div className="player-ShuffleButton" onClick={onClick}>
          <img src={shuffleDarkImage} style={{width: 24}} alt="Shuffle Button"/>
        </div>
    );
};

export default ShuffleButton;
