import React, {FunctionComponent, PropsWithChildren, useContext, useEffect, useState} from 'react';
import MainNavigation from './main-navigation';
import Logo from './logo';
import Button, {ButtonStyle} from '../buttons/button';
import './header.css';
import AppContext from '../../contexts/app-context';
import AuthUser from '../../models/auth-user';
import {Link, NavigateFunction, NavigateOptions, To, useNavigate} from 'react-router-dom';
import NavButtons from './nav-buttons';
import LoginRegisterModal from '../auth/login-register-modal';
import LoginMethod from '../../enums/login-method';
import Hamburger from '../common/hamburger';
import classNames from 'classnames';
import {commifyNumber} from '../../utils/strings';

type HeaderProps = PropsWithChildren;

const Header: FunctionComponent<HeaderProps> = () => {
    const appContext = useContext(AppContext);

    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [saveCount, setSaveCount] = useState<number | undefined>(appContext.demoService?.saveCount);

    useEffect(() => {
        function saveCountUpdate(saveCount: number | undefined) {
            setSaveCount(saveCount)
        }
        appContext.demoService?.getSaveCount(saveCountUpdate);
        return () => {
            appContext.demoService?.removeOnSaveCountChanged(saveCountUpdate);
        }
    }, [appContext.demoService]);

    function handleLogin(authUser: AuthUser) {
        appContext.setAuthUser(authUser).then(() => {
            appContext.modalManager.popModal();
        });
    }

    const nav = useNavigate();
    const handleNav: NavigateFunction = (to: To | number, options?: NavigateOptions) => {
        if (showMenu) setShowMenu(false);
        nav(to as To, options);
    };

    return (
        <div className="container">
            <div className="pages-Header">
                <div className="pages-Header-left">
                    <Link to="/"><Logo/></Link>
                </div>
                <div className="pages-Header-right">
                    <div className="pages-Header-nav">
                        <div className="pages-Header-menu-toggle">
                            <Hamburger onClick={() => setShowMenu(!showMenu)}/>
                        </div>
                        <div className={classNames('pages-Header-nav-options', {'visible-mobile': showMenu})}>
                            <MainNavigation nav={handleNav} saveCount={saveCount}/>

                            {appContext.authUser === undefined ? (
                                <NavButtons>
                                    <Button onClick={() => {
                                        appContext.modalManager.pushModal(
                                            <LoginRegisterModal login={handleLogin} loginMethod={LoginMethod.Login}/>
                                        )
                                    }}>Log In</Button>
                                    <Button style={ButtonStyle.Primary} onClick={() => {
                                        appContext.modalManager.pushModal(
                                            <LoginRegisterModal login={handleLogin} loginMethod={LoginMethod.Register}/>
                                        )
                                    }}>Register</Button>
                                </NavButtons>
                            ) : (
                                <NavButtons>
                                    <Button onClick={() => {
                                        appContext.setAuthUser();
                                        nav('/');
                                    }}>
                                        Log Out
                                    </Button>
                                    <Button style={ButtonStyle.Primary} onClick={() => {
                                        nav('/profile'/*, {state: {showSaves: true}}*/);
                                    }}>
                                        Profile
                                        {saveCount === undefined ? null : <> ({commifyNumber(saveCount)})</>}
                                    </Button>
                                    {/*
                                     <Button style={ButtonStyle.Primary}
                                            onClick={() => {
                                                const modalOptions = new ModalOptions();
                                                modalOptions.canClickOutside = false;
                                                appContext.modalManager.pushModal(
                                                    <DemoUploadModal/>, modalOptions);
                                            }}>Upload</Button>
                                    */}
                                </NavButtons>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
