import React, {FunctionComponent, PropsWithChildren, useEffect, useState} from 'react';
import Button, {ButtonStyle} from './button';
import OptionValue from '../../models/option-value';
import './option-value-button.css';
import DropDownHandleLight from '../../assets/img/dropdown-handle-light.svg';
import DropDownHandleDark from '../../assets/img/dropdown-handle-dark.svg';
import ListOptions from '../common/list-options';
import ListOption from '../common/list-option';
import classNames from 'classnames';

type OptionValueButtonProps = PropsWithChildren<{
    buttonStyle?: ButtonStyle
    className?: string
    invertIndicator?: boolean
    options?: Array<OptionValue>
    onSelect: (value?: OptionValue) => void
    showIndicator?: boolean
    noOptionText?: string // Adds value as first option in select and returns onSelect() with empty value
}>;

const OptionValueButton: FunctionComponent<OptionValueButtonProps> = ({
                                                                          buttonStyle,
                                                                          children,
                                                                          className,
                                                                          invertIndicator,
                                                                          noOptionText,
                                                                          onSelect,
                                                                          options,
                                                                          showIndicator
                                                                      }) => {
    const [showOptions, setShowOptions] = useState<boolean>(false);

    useEffect(() => {
        function handleClickOutside(ev: MouseEvent) {
            setShowOptions(false);
            document.removeEventListener('click', handleClickOutside);
        }

        if (showOptions) {
            document.addEventListener('click', handleClickOutside);
        }
        return () => {
            if (showOptions) document.removeEventListener('click', handleClickOutside);
        };
    }, [showOptions]);

    return (
        <div className={classNames('buttons-OptionValueButton', className)} onClick={ev => {
            // console.log('Outer click:', showOptions);
            // if (!showOptions) ev.stopPropagation();
            setShowOptions(!showOptions);
        }}>
            <Button style={buttonStyle} onClick={() => {
                // console.log('TEst:', showOptions);
                setShowOptions(!showOptions);
            }}>
                {children}
                {showIndicator !== false && <img src={invertIndicator ? DropDownHandleDark : DropDownHandleLight} alt=""/>}
            </Button>
            {showOptions ? (
                <div className="buttons-OptionValueButton-options">
                    {noOptionText === undefined ?
                        null
                        :
                        <ListOption key="empty"
                                    onClick={() => onSelect()}>
                            {noOptionText}
                        </ListOption>}

                    {options && <ListOptions>
                        {options.map(option => (
                            <ListOption key={option.id} onClick={() => onSelect(option)}>
                                {option.value}
                            </ListOption>
                        ))}
                    </ListOptions>}
                </div>
            ) : null}
        </div>
    );
}

export default OptionValueButton;
