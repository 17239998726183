export class Demo {
    constructor(public name: string,
                public id: number = 0,
                public likes: number = 0,
                public userId: number = 0,
                public url: string = '',
                public albumCover: string = '',
                public doesLike: boolean = false,
                public saved: boolean = false,
                public shareUrl: string = ''
    ) {
    }
}
