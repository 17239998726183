import React, {FunctionComponent, ReactEventHandler, useEffect, useRef, useState} from 'react';
import Controls from './controls';
import AlbumCover from './album-cover';
import './player.css';
import classNames from 'classnames';
import PlayerContext from '../../contexts/player-context';
import Theme from '../../enums/theme';
import {Demo} from "../../models/demo";
import PlayerService from "../../services/player-service";
import {PlayerEventHandler} from "../../types/player-status-handler";

type PlayerProps = {
    service: PlayerService
    theme?: Theme
    onToggleLike?: (demo: Demo) => void
    onSelectVoice?: (demo: Demo) => void
    onShare?: (demo: Demo) => void
    onRewindClicked?: () => void // Override default "rewind" functionality
    onFastFowardClicked?: () => void // Override default "fast-forward" functionality
};

const Player: FunctionComponent<PlayerProps> = ({
                                                    onFastFowardClicked,
                                                    onRewindClicked,
                                                    onSelectVoice,
                                                    onShare,
                                                    onToggleLike,
                                                    service,
                                                    theme
                                                }) => {
    if (theme === undefined) theme = Theme.Dark;
    const [percentComplete, setPercentComplete] = useState<number>(0);
    // const [playerStatus, setPlayerStatus] = useState<PlayerStatusEvent | undefined>();
    const [demo, setDemo] = useState<Demo | undefined>();
    const audioRef = useRef<HTMLAudioElement>(null);

    const handleTimeUpdate: ReactEventHandler<HTMLAudioElement> = (ev) => {
        // console.log('Updating time:', ev.currentTarget.currentTime, ev.currentTarget.duration);
        const percent = Math.round(ev.currentTarget.currentTime / ev.currentTarget.duration * 100_00) / 100;
        if (percent === 100) {
            service.stop();
        }
        setPercentComplete(percent);
    }

    useEffect(() => {
        const handleStatusUpdate: PlayerEventHandler = (status) => {
            if (!status.current || (status.current && status.current.id !== demo?.id)) setDemo(status.current);
            // console.log('ISSUE HERE');
            if (status.isPlaying && audioRef.current?.paused) audioRef.current?.play();
            else if (!status.isPlaying && !audioRef.current?.paused) audioRef.current?.pause();
        }
        service.getStatus(handleStatusUpdate);
        return () => {
            service.removeOnStatusChanged(handleStatusUpdate);
        }
    }, [demo?.id, service]);

    useEffect(() => {
        if (!demo) return;
        const status = service.getStatus();
        // console.log('ISSUE HERE2: Is Playing: ', status.isPlaying, 'Paused:', audioRef.current?.paused);
        if (status.isPlaying) {
            if (audioRef.current?.paused) {
                audioRef.current?.play();
            }
        } else {
            if (!audioRef.current?.paused) audioRef.current?.pause();
        }
    }, [demo, service]);

    return (
        <PlayerContext.Provider value={{
            service,
            theme
        }}>
            <div className={classNames('player-Player', 'Theme-' + theme)}>
                {/*<audio ref={audioRef2} onTimeUpdate={handleTimeUpdate} onLoadedMetadata={() => console.log('Metadata read')} onLoadedData={() => console.log('Data loaded')}>*/}
                {/*    <source src={demo?.url} type="audio/mpeg"/>*/}
                {/*</audio>*/}
                <audio ref={audioRef}
                       onTimeUpdate={handleTimeUpdate}
                    // onLoadedMetadata={() => console.log('Metadata read')}
                    // onLoadedData={() => console.log('Data loaded')}
                       src={demo?.url}
                />
                <div className="player-Player-album">
                    <AlbumCover size={200}
                                image={demo?.albumCover}
                                label={demo?.name}
                                doesLike={demo ? demo.doesLike : false}
                                onLike={demo && onToggleLike ? () => onToggleLike(demo) : undefined}
                    />
                </div>
                {demo ? (
                    <div className="player-Player-name">
                        {demo?.name}
                        <NameUnderline/>
                    </div>
                ) : null}
                <Controls isPlaying={service.getStatus().isPlaying}
                          percent={percentComplete}
                          onRewindClicked={() => {
                              if (onRewindClicked) { // Override default behavior
                                  onRewindClicked();
                              } else { // Default to fast fowrarding the track by x seconds
                                  if (audioRef && audioRef.current) {
                                      audioRef.current.currentTime = audioRef.current.currentTime - 5;
                                  }
                              }
                          }}
                          onFastFowardClicked={() => {
                              if (onFastFowardClicked) { // Override default behavior
                                  onFastFowardClicked();
                              } else { // Default to fast fowrarding the track by x seconds
                                  if (audioRef && audioRef.current) {
                                      audioRef.current.currentTime = audioRef.current.currentTime + 5;
                                  }
                              }
                          }}

                    // onShuffleClicked={() => {
                    //     console.log('Player.Shuffle');
                    // }}
                    // onLoopClicked={() => {
                    //     console.log('Player.Loop');
                    // }}
                          onSelectVoice={demo && onSelectVoice ? () => onSelectVoice(demo) : undefined}
                          onShare={demo && onShare ? () => onShare(demo) : undefined}
                          onPlayClicked={() => {
                              // console.log('On Play Clciked');
                              if (service.getStatus().isPlaying) service.stop();
                              else service.play();
                          }}
                />
            </div>
        </PlayerContext.Provider>
    );
};

const NameUnderline: FunctionComponent = () => {
    return <div className="player-NameUnderline"></div>
};

export default Player;
