import React, {FunctionComponent} from 'react';

const Jobs: FunctionComponent = () => {
    return (
        <div className="container">
            <div className="hero-container row">
                <div className="col-6 col-md-12">
                    <div className="hero">
                        <h1>Jobs</h1>
                        <p>Check back soon.</p>
                    </div>
                </div>
                <div className="col-6 col-md-12"></div>
            </div>
        </div>
    );
};

export default Jobs;
