import React, {FunctionComponent, PropsWithChildren} from 'react';
import './label.css';

type LabelProps = PropsWithChildren;

const Label: FunctionComponent<LabelProps> = ({children}) => {
    return (
        <label className="form-Label">{children}</label>
    );
};

export default Label;
