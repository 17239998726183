import React, {FunctionComponent, ReactEventHandler, useEffect, useRef, useState} from 'react';
import './home.css';
import HomePageTemplate from './home-template';

const HomePageUnmute: FunctionComponent = () => {
    const [showControls, setShowControls] = useState<boolean>(true);
    const [isMuted, setIsMuted] = useState<boolean | undefined>();
    const videoRef = useRef<HTMLVideoElement>(null);
    const hoverTimer = useRef<number>();

    const toggleMuted = () => {
        if (videoRef.current !== null) {
            setIsMuted(videoRef.current.muted = !videoRef.current.muted);
        }
    }

    const autoHideControls = () => {
        if (hoverTimer.current) window.clearTimeout(hoverTimer.current);
        if (!showControls) setShowControls(true);
        hoverTimer.current = window.setTimeout(() => {
            setShowControls(false);
        }, 3000);
    }

    const handleMouseOver = () => {
        if (hoverTimer.current) window.clearTimeout(hoverTimer.current);
        if (!showControls) setShowControls(true);
        autoHideControls();
    }

    const handleMouseOut = () => {
        if (hoverTimer.current) window.clearTimeout(hoverTimer.current);
        hoverTimer.current = window.setTimeout(() => {
            setShowControls(false);
        }, 250);
    }

    useEffect(() => {
        if (videoRef.current) {
            setIsMuted(videoRef.current.muted);
        } else {
            setIsMuted(undefined);
        }

    }, [videoRef.current]);

    return <HomePageTemplate>

        <video ref={videoRef}
               preload="auto"
               autoPlay
               muted
               loop
               className="video-bg"
               onClick={() => toggleMuted()}
               onMouseOver={handleMouseOver}
               onMouseOut={handleMouseOut}
               onMouseMove={() => autoHideControls()}
        >
            <source src="/assets/videos/intro.web" type="video/webm"/>
            <source src="/assets/videos/intro.mp4" type="video/mp4"/>
        </video>

        <div className="video-hero-controls"
             onMouseOver={handleMouseOver}
             onMouseOut={handleMouseOut}>
            {showControls && <svg className="clickable" fill="#fff" viewBox="0 0 100 100" width="100px" height="100px" onClick={() => toggleMuted()}>
                <path d="M0,32 L20,32 L45,0 L50,0 L50,100 L45,100 L20,68 L0,68 z" fill="#fff"/>
                {videoRef.current ? (
                    <>
                        {isMuted ? <>
                            {/* Pause "X" */}
                            <path d="M60,65 L90,35" stroke="#fff" strokeWidth="7" strokeLinecap="round"/>
                            <path d="M60,35 L90,65" stroke="#fff" strokeWidth="7" strokeLinecap="round"/>
                            </> : <>
                            {/* Sound Waves */}
                            <path d="M70,20 C90,30,90,70,70,80" fill="transparent" stroke="#fff" strokeWidth="7" strokeLinecap="round"/>
                            <path d="M60,30 C73,40,73,60,60,70" fill="transparent" stroke="#fff" strokeWidth="7" strokeLinecap="round"/>
                            </>}
                    </>
                    ) : null}
            </svg>}
        </div>
    </HomePageTemplate>
}

export default HomePageUnmute;
