import React, {FunctionComponent} from 'react';

type LogoProps = {};

const Logo: FunctionComponent<LogoProps> = () => {

    return (
        <div><img src="/assets/img/logo.png" style={{width: 150}} alt="Logo" /></div>
    );
};

export default Logo;
